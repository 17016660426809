import {
	ProfilesBitcoinFarmCollectResponseBody,
	ProfilesBitcoinFarmUpgradeResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const upgradeBitcoinFarm = createAsyncThunk(
	'profiles/upgradeBitcoinFarm',
	async (): Promise<ProfilesBitcoinFarmUpgradeResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/bitcoinFarm/upgradeBitcoinFarm'
		)
		return response
	}
)

export const collectBitcoinFarm = createAsyncThunk(
	'profiles/collectBitcoinFarm',
	async (): Promise<ProfilesBitcoinFarmCollectResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/bitcoinFarm/collectBitcoinFarm'
		)
		return response
	}
)
