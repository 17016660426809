import {
	PLAYER_RANKS,
	getProfileRankFromProfileExperience
} from '@allardvanderouw/mercenariesonline-commons'
import {
	Avatar,
	Card,
	CardHeader,
	LinearProgress,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { getProfileRankTitleText } from '../../../util/profile/level'

const ProfileCard = () => {
	const hero = useSelector((state: RootState) => state.profile.entity.hero)
	const currentRank = getProfileRankFromProfileExperience(hero.experience)
	const nextRank = PLAYER_RANKS.find((rank) => rank.level > currentRank.level)

	let rankProgress
	let rankProgressText
	if (nextRank) {
		const totalExperienceToNextRank =
			nextRank.experience - currentRank.experience
		const currentExperienceInRank = hero.experience - currentRank.experience
		const progressValue = Math.floor(
			(currentExperienceInRank / totalExperienceToNextRank) * 100
		)
		rankProgress = (
			<LinearProgress
				color="secondary"
				value={progressValue}
				variant="determinate"
			/>
		)
		rankProgressText = `${currentExperienceInRank.toLocaleString()} / ${totalExperienceToNextRank.toLocaleString()} ${t(
			'app:Generic.xp'
		)} (${progressValue}%)`
	} else {
		rankProgressText = `${hero.experience.toLocaleString()} ${t(
			'app:Generic.xp'
		)}`
	}

	return (
		<Card sx={{ backgroundColor: blueGrey[700] }}>
			<CardHeader
				sx={{ p: 1 }}
				avatar={
					<Avatar
						sx={{
							backgroundColor: blueGrey[500],
							color: '#fff',
							fontWeight: 'bold'
						}}
						variant="rounded"
					>
						{currentRank.level}
					</Avatar>
				}
				title={
					<Typography variant="subtitle2">
						{getProfileRankTitleText(currentRank.title)} {hero.name}
					</Typography>
				}
				subheader={rankProgressText}
			/>
			{rankProgress}
		</Card>
	)
}

export default ProfileCard
