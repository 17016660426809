import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Box,
	Button,
	Grid,
	Paper,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux'
import { forgotPassword } from '../../redux/auth/thunks'
import { isValidEmail } from '../../util/validation/inputs'

const ForgotPassword = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isCompleted, setIsCompleted] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (emailError && isValidEmail(event.target.value)) {
			setEmailError(false)
		}
		setEmail(event.target.value)
	}

	const handleForgotPasswordSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			if (!isValidEmail(email)) {
				setEmailError(true)
			} else {
				setErrorText('')
				setIsLoading(true)
				const wrappedForgotPasswordResult = await dispatch(
					forgotPassword({ email })
				)
				unwrapResult(wrappedForgotPasswordResult) // unwrap to catch errors
				setIsCompleted(true)
				setIsLoading(false)
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsLoading(false)
		}
	}

	const handleBackToLoginClick = () => {
		navigate('/')
	}

	let error
	if (errorText) {
		error = <Alert severity="error">{errorText}</Alert>
	}

	let content
	if (isCompleted) {
		content = (
			<>
				<Typography variant="h5" align="center">
					{t('app:Auth.forgotPasswordSuccessTitle')}
				</Typography>
				<Typography align="center">
					{t('app:Auth.forgotPasswordSuccessDescription1')}
					<br />
					{t('app:Auth.forgotPasswordSuccessDescription2')}
				</Typography>
				<Typography align="center">
					{t('app:Auth.forgotPasswordSuccessDescription3')}
				</Typography>
			</>
		)
	} else {
		content = (
			<>
				<Typography variant="h5">{t('app:Auth.forgotPassword')}</Typography>
				{error}
				<TextField
					required
					autoFocus
					variant="filled"
					label={t('app:Auth.email')}
					value={email}
					onChange={handleEmailChange}
					disabled={isLoading}
					helperText={emailError ? t('app:Auth.emailError') : ''}
					error={emailError}
				/>
				<LoadingButton
					variant="contained"
					size="large"
					type="submit"
					loading={isLoading}
				>
					{t('app:Auth.forgotPassword')}
				</LoadingButton>
			</>
		)
	}

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<form onSubmit={handleForgotPasswordSubmit}>
				<Grid container spacing={2} padding={2}>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<img
								alt="Mercenaries Online *i18n*"
								src="/assets/images/auth-logo.png"
								width="100%"
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<Paper elevation={1}>
								<Stack padding={2} spacing={2}>
									{content}
									<Button
										fullWidth
										variant="contained"
										size="large"
										disabled={isLoading}
										onClick={handleBackToLoginClick}
									>
										{t('app:Auth.backToLogin')}
									</Button>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default ForgotPassword
