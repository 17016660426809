import { init } from 'i18next'
import { SnackbarProvider } from 'notistack'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App/App'
import locales from './locales'
import store from './redux'

export const initialize = async () => {
	// Initialize i18n
	await init({
		lng: window.navigator.language.slice(0, 2),
		fallbackLng: 'en',
		resources: locales
	})

	const rootElement = document.getElementById('root')
	if (!rootElement)
		throw new Error('Root element not found. Unable to create root.')
	const root = createRoot(rootElement)

	root.render(
		<Provider store={store}>
			<BrowserRouter>
				<SnackbarProvider
					maxSnack={5}
					anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
				>
					<App />
				</SnackbarProvider>
			</BrowserRouter>
		</Provider>
	)
}
