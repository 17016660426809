import { OptionsObject } from 'notistack'

export interface SnackbarNotification {
	message: string
	options?: OptionsObject
}

export interface SnackbarNotificationEvent extends CustomEvent {
	detail: SnackbarNotification
}

export const isSnackbarNotificationEvent = (
	input: SnackbarNotificationEvent | Event
): input is SnackbarNotificationEvent =>
	(input as SnackbarNotificationEvent).detail !== undefined &&
	(input as SnackbarNotificationEvent).detail?.message !== undefined
