import { BASE_MISSIONS_MAP } from '@allardvanderouw/mercenariesonline-commons'
import { ProfileMission, Tier } from '@allardvanderouw/mercenariesonline-types'
import {
	faCog,
	faDollarSign,
	faStar,
	faTint,
	faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	ButtonBase,
	Card,
	CardHeader,
	Rating,
	Stack,
	SvgIcon,
	SxProps,
	Typography,
	useTheme
} from '@mui/material'
import { blueGrey, green, grey, red } from '@mui/material/colors'
import { t } from 'i18next'
import { LatLngExpression } from 'leaflet'
import { Marker } from 'react-leaflet-marker'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { ActiveDialog, DialogKey } from '../../../../types'
import { isMissionBountyObjective } from '../../../../util/missions/is-mission-bounty-objective'
import {
	getMissionRatingValue,
	getMissionTypeIcon,
	getMissionTypeText
} from '../../../../util/profile/missions'

interface MissionMarkerProps {
	mission: ProfileMission
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const MissionMarker: React.FC<MissionMarkerProps> = ({
	mission,
	setActiveDialog
}) => {
	const theme = useTheme()

	const activeBounty = useSelector(
		(state: RootState) => state.profile.entity.bountyHub.activeBounty
	)

	let experienceReward
	if (mission.experienceReward) {
		experienceReward = (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
			>
				<SvgIcon fontSize="inherit">
					<FontAwesomeIcon color={green[800]} icon={faUserPlus} />
				</SvgIcon>
				<Typography variant="caption" color={green[800]}>
					{mission.experienceReward.toLocaleString()}
				</Typography>
			</Stack>
		)
	}

	let cashReward
	if (mission.cashReward) {
		cashReward = (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
			>
				<SvgIcon fontSize="inherit">
					<FontAwesomeIcon color={green[800]} icon={faDollarSign} />
				</SvgIcon>
				<Typography variant="caption" color={green[800]}>
					{mission.cashReward.toLocaleString()}
				</Typography>
			</Stack>
		)
	}

	let weaponPartsReward
	if (mission.weaponPartsReward) {
		weaponPartsReward = (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
			>
				<SvgIcon fontSize="inherit">
					<FontAwesomeIcon color={green[800]} icon={faCog} />
				</SvgIcon>
				<Typography variant="caption" color={green[800]}>
					{mission.weaponPartsReward.toLocaleString()}
				</Typography>
			</Stack>
		)
	}

	let fuelCost
	if (mission.fuelCost) {
		fuelCost = (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
			>
				<SvgIcon fontSize="inherit">
					<FontAwesomeIcon icon={faTint} color="black" />
				</SvgIcon>
				<Typography variant="caption" color="black">
					{mission.fuelCost.toLocaleString()}
				</Typography>
			</Stack>
		)
	}

	const position: LatLngExpression = [
		mission.position.latitude,
		mission.position.longitude
	]

	const isBountyObjective = isMissionBountyObjective(mission, activeBounty)
	let cardStyle: SxProps = {
		width: '100%',
		backgroundColor: blueGrey[700]
	}
	if (isBountyObjective) {
		cardStyle = {
			...cardStyle,
			boxShadow: `0 0 5px 5px ${theme.palette.secondary.main}}`
		}
	}
	if (mission.isStory && mission.tier === Tier.T10) {
		// First story mission (after sign-up)
		cardStyle = {
			...cardStyle,
			boxShadow: `0 0 15px 15px ${theme.palette.secondary.main}}`
		}
	}

	let info
	if (mission.hasRequiredLevel) {
		info = (
			<>
				{experienceReward}
				{cashReward}
				{weaponPartsReward}
				{fuelCost}
			</>
		)
	} else {
		const requiredLevel = BASE_MISSIONS_MAP.get(mission.tier)?.requiredLevel
		info = (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0.5}
			>
				<Typography variant="caption" color={blueGrey[800]}>
					{t('app:Generic.requiresLevel')} {requiredLevel}
				</Typography>
			</Stack>
		)
	}

	return (
		<div style={{ position: 'absolute' }}>
			<Marker position={position} size={[200, 80]}>
				<ButtonBase
					disabled={!mission.hasRequiredLevel}
					sx={{ width: '100%' }}
					onClick={() =>
						setActiveDialog({ key: DialogKey.StartMission, data: mission })
					}
				>
					<Card sx={cardStyle}>
						<CardHeader
							sx={{ padding: 1, textAlign: 'left' }}
							avatar={
								<Avatar
									variant="rounded"
									sx={{
										marginRight: -1,
										backgroundColor: mission.hasRequiredLevel
											? red['A700']
											: grey['500']
									}}
								>
									<SvgIcon fontSize="medium" sx={{ color: 'white' }}>
										<FontAwesomeIcon
											icon={getMissionTypeIcon(
												mission.typeKey,
												mission.isStory
											)}
										/>
									</SvgIcon>
								</Avatar>
							}
							title={
								<Typography
									variant="subtitle2"
									sx={{ color: '#fff', fontWeight: 500 }}
								>
									{getMissionTypeText(
										mission.typeKey,
										mission.tier,
										mission.isStory
									)}
								</Typography>
							}
							subheader={
								<Rating
									readOnly
									size="small"
									value={getMissionRatingValue(mission.tier)}
									precision={0.5}
									icon={
										<SvgIcon fontSize="inherit">
											<FontAwesomeIcon icon={faStar} />
										</SvgIcon>
									}
									emptyIcon={
										<SvgIcon fontSize="inherit">
											<FontAwesomeIcon icon={faStar} />
										</SvgIcon>
									}
								/>
							}
						/>
						<Stack
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{ backgroundColor: grey[300] }}
							direction="row"
							spacing={1}
							height={20}
							color="#ffffff"
						>
							{info}
						</Stack>
					</Card>
				</ButtonBase>
			</Marker>
		</div>
	)
}

export default MissionMarker
