import { LoadingButton } from '@mui/lab'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useAppDispatch } from '../../../../redux'
import { changePassword } from '../../../../redux/user/thunks'
import {
	ActiveDialog,
	DialogKey,
	SettingsDialogSubMenu
} from '../../../../types'
import { sendSnackbarNotification } from '../../../../util/notifications/notifications'
import {
	isValidConfirmPassword,
	isValidPassword
} from '../../../../util/validation/inputs'

interface SettingsSubMenuChangePasswordProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
	setSubMenu: (subMenu: SettingsDialogSubMenu) => void
}

const SettingsSubMenuChangePassword: React.FC<
	SettingsSubMenuChangePasswordProps
> = ({ setActiveDialog, setSubMenu }) => {
	const dispatch = useAppDispatch()
	const [errorText, setErrorText] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [oldPassword, setOldPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [newPasswordError, setNewPasswordError] = useState(false)
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false)

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleOldPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setOldPassword(event.target.value)
	}

	const handleNewPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (newPasswordError && isValidPassword(event.target.value)) {
			setNewPasswordError(false)
		}
		setNewPassword(event.target.value)
	}

	const handleConfirmNewPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (
			confirmNewPasswordError &&
			isValidConfirmPassword(event.target.value, newPassword)
		) {
			setConfirmNewPasswordError(false)
		}
		setConfirmNewPassword(event.target.value)
	}

	const onChangePasswordClick = async () => {
		try {
			if (!isValidPassword(newPassword)) {
				setNewPasswordError(true)
			} else if (!isValidConfirmPassword(confirmNewPassword, newPassword)) {
				setConfirmNewPasswordError(true)
			} else {
				setIsLoading(true)
				const wrappedResult = await dispatch(
					changePassword({ oldPassword, newPassword })
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
				sendSnackbarNotification(
					t('app:Settings.ChangePassword.passwordChanged'),
					'success'
				)
				setActiveDialog({ key: DialogKey.None })
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsLoading(false)
		}
	}

	const onBackToSettingsClick = () => {
		setSubMenu(SettingsDialogSubMenu.None)
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<>
			{errorDialog}
			<TextField
				autoFocus
				required
				label={t('app:Settings.ChangePassword.currentPassword')}
				variant="filled"
				type="password"
				value={oldPassword}
				onChange={handleOldPasswordChange}
				disabled={isLoading}
			/>
			<TextField
				required
				label={t('app:Settings.ChangePassword.newPassword')}
				variant="filled"
				type="password"
				value={newPassword}
				onChange={handleNewPasswordChange}
				disabled={isLoading}
				helperText={newPasswordError ? t('app:Auth.passwordError') : ''}
				error={newPasswordError}
			/>
			<TextField
				required
				label={t('app:Settings.ChangePassword.newPasswordConfirm')}
				variant="filled"
				type="password"
				value={confirmNewPassword}
				onChange={handleConfirmNewPasswordChange}
				disabled={isLoading}
				helperText={
					confirmNewPasswordError ? t('app:Auth.confirmPasswordError') : ''
				}
				error={confirmNewPasswordError}
			/>
			<LoadingButton
				fullWidth
				loading={isLoading}
				variant="contained"
				onClick={onChangePasswordClick}
			>
				{t('app:Settings.changePassword')}
			</LoadingButton>
			<Button fullWidth variant="contained" onClick={onBackToSettingsClick}>
				{t('app:Generic.back')}
			</Button>
		</>
	)
}

export default SettingsSubMenuChangePassword
