import {
	ProfilesMissionFinishResponseBody,
	SignedMissionCode
} from '@allardvanderouw/mercenariesonline-types'
import {
	Box,
	Button,
	Card,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Paper,
	Stack,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../redux'
import { finishMission } from '../../../redux/profile/thunks'
import { ActiveDialog, DialogKey } from '../../../types'
import { getScoreLetter } from '../../../util/missions/get-score-letter'
import MissionLootItemFragment from './ArmoryDialog/MissionLootItemFragment'
import DialogHeader from './DialogHeader'

interface FinishMissionDialogProps {
	missionReportCode: SignedMissionCode
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const FinishMissionDialog: React.FC<FinishMissionDialogProps> = ({
	missionReportCode,
	setActiveDialog
}) => {
	const dispatch = useAppDispatch()
	const [isFinishing, setIsFinishing] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [finishMissionResponse, setFinishMissionResponse] =
		useState<ProfilesMissionFinishResponseBody | null>(null)

	useEffect(() => {
		const startFinishMission = async () => {
			try {
				setIsFinishing(true)

				const wrappedResult = await dispatch(
					finishMission({ code: missionReportCode })
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
				setFinishMissionResponse(
					wrappedResult.payload as ProfilesMissionFinishResponseBody
				)
			} catch (error: any) {
				setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			} finally {
				setIsFinishing(false)
			}
		}
		startFinishMission()
	}, [dispatch, missionReportCode])

	const handleClose = () => {
		setActiveDialog({ key: DialogKey.None })
	}

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleGoToArmoryClick = () => {
		setActiveDialog({
			key: DialogKey.Armory
		})
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	if (isFinishing || !finishMissionResponse) {
		return (
			<Dialog open={true} fullWidth>
				<DialogContent>
					<Stack width="100%" direction="row" justifyContent="center">
						<CircularProgress />
					</Stack>
				</DialogContent>
			</Dialog>
		)
	}

	let experienceReward
	if (finishMissionResponse.meta.rewards?.experience.total) {
		experienceReward = (
			<>
				<Divider>
					<Typography variant="h6">
						{t('app:FinishMission.experienceReward')}
					</Typography>
				</Divider>
				<Stack display="flex" flex={1} flexDirection="row">
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.objectives')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.experience.objectives.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.scoreBonus')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.experience.scoreBonus.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.membershipBonus')}
							</Typography>
							<Typography
								variant="h6"
								color={
									finishMissionResponse.meta.rewards.isMembershipActive
										? 'secondary'
										: 'error'
								}
							>
								{finishMissionResponse.meta.rewards.isMembershipActive
									? ''
									: '('}
								+
								{finishMissionResponse.meta.rewards?.experience.membershipBonus.toLocaleString()}
								{finishMissionResponse.meta.rewards.isMembershipActive
									? ''
									: ')'}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.total')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.experience.total.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
				</Stack>
			</>
		)
	}

	let cashReward
	if (finishMissionResponse.meta.rewards?.cash.total) {
		cashReward = (
			<>
				<Divider>
					<Typography variant="h6">
						{t('app:FinishMission.cashReward')}
					</Typography>
				</Divider>
				<Stack display="flex" flex={1} flexDirection="row">
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.objectives')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.cash.objectives.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.scoreBonus')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.cash.scoreBonus.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.membershipBonus')}
							</Typography>
							<Typography
								variant="h6"
								color={
									finishMissionResponse.meta.rewards.isMembershipActive
										? 'secondary'
										: 'error'
								}
							>
								{finishMissionResponse.meta.rewards.isMembershipActive
									? ''
									: '('}
								+
								{finishMissionResponse.meta.rewards?.cash.membershipBonus.toLocaleString()}
								{finishMissionResponse.meta.rewards.isMembershipActive
									? ''
									: ')'}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.total')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.cash.total.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
				</Stack>
			</>
		)
	}

	let weaponPartsReward
	if (finishMissionResponse.meta.rewards?.weaponParts.total) {
		weaponPartsReward = (
			<>
				<Divider>
					<Typography variant="h6">
						{t('app:FinishMission.weaponPartsReward')}
					</Typography>
				</Divider>
				<Stack display="flex" flex={1} flexDirection="row">
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.objectives')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.weaponParts.objectives.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.scoreBonus')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.weaponParts.scoreBonus.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.membershipBonus')}
							</Typography>
							<Typography
								variant="h6"
								color={
									finishMissionResponse.meta.rewards.isMembershipActive
										? 'secondary'
										: 'error'
								}
							>
								{finishMissionResponse.meta.rewards.isMembershipActive
									? ''
									: '('}
								+
								{finishMissionResponse.meta.rewards?.weaponParts.membershipBonus.toLocaleString()}
								{finishMissionResponse.meta.rewards.isMembershipActive
									? ''
									: ')'}
							</Typography>
						</Paper>
					</Box>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.total')}
							</Typography>
							<Typography variant="h6" color="secondary">
								+
								{finishMissionResponse.meta.rewards?.weaponParts.total.toLocaleString()}
							</Typography>
						</Paper>
					</Box>
				</Stack>
			</>
		)
	}

	let loot
	if (
		finishMissionResponse.meta.rewards?.items &&
		finishMissionResponse.meta.rewards?.items.length > 0
	) {
		const lootedItems = finishMissionResponse.meta.rewards.items.map((item) => (
			<MissionLootItemFragment key={`item-${item.ID}`} lootBagItem={item} />
		))
		loot = (
			<>
				<Divider>
					<Typography variant="h6">{t('app:FinishMission.loot')}</Typography>
				</Divider>
				<Box padding={1}>
					<Card sx={{ backgroundColor: blueGrey[700] }}>
						<CardHeader
							sx={{ padding: 0.5 }}
							title={
								<Stack flexDirection="row" justifyContent="center">
									{lootedItems}
								</Stack>
							}
						/>
						<Button
							fullWidth
							variant="contained"
							sx={{ borderRadius: 0 }}
							onClick={handleGoToArmoryClick}
						>
							{t('app:FinishMission.goToArmory')}
						</Button>
					</Card>
				</Box>
			</>
		)
	}

	let missionText
	let missionTextColor
	if (finishMissionResponse.meta.summary?.objectivesComplete) {
		missionText = t('app:FinishMission.missionCompleted')
		missionTextColor = 'success.main'
	} else {
		missionText = t('app:FinishMission.missionFailed')
		missionTextColor = 'error.main'
	}

	return (
		<>
			{errorDialog}
			<Dialog open={true} fullWidth onClose={handleClose}>
				<DialogHeader
					activeDialogKey={DialogKey.FinishMission}
					setActiveDialog={setActiveDialog}
				/>
				<Box
					sx={{
						border: `4px solid ${blueGrey[500]}`,
						backgroundColor: blueGrey[800],
						padding: 1
					}}
				>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 1,
								textAlign: 'center',
								backgroundColor: blueGrey[900]
							}}
						>
							<Typography variant="h5" color={missionTextColor}>
								{missionText}
							</Typography>
						</Paper>
					</Box>
					<Divider>
						<Typography variant="h6">{t('app:FinishMission.score')}</Typography>
					</Divider>
					<Stack display="flex" flex={1} flexDirection="row">
						<Box flex={1} padding={1}>
							<Paper
								sx={{
									padding: 0.5,
									textAlign: 'center',
									backgroundColor: blueGrey[700]
								}}
							>
								<Typography variant="caption">
									{t('app:FinishMission.widowmaker')}
								</Typography>
								<Typography variant="h6" color="secondary">
									{getScoreLetter(
										finishMissionResponse.meta.score?.widowmakerScore
									)}
								</Typography>
							</Paper>
						</Box>
						<Box flex={1} padding={1}>
							<Paper
								sx={{
									padding: 0.5,
									textAlign: 'center',
									backgroundColor: blueGrey[700]
								}}
							>
								<Typography variant="caption">
									{t('app:FinishMission.sharpshooter')}
								</Typography>
								<Typography variant="h6" color="secondary">
									{getScoreLetter(
										finishMissionResponse.meta.score?.sharpshooterScore
									)}
								</Typography>
							</Paper>
						</Box>
						<Box flex={1} padding={1}>
							<Paper
								sx={{
									padding: 0.5,
									textAlign: 'center',
									backgroundColor: blueGrey[700]
								}}
							>
								<Typography variant="caption">
									{t('app:FinishMission.neatnik')}
								</Typography>
								<Typography variant="h6" color="secondary">
									{getScoreLetter(
										finishMissionResponse.meta.score?.neatnikScore
									)}
								</Typography>
							</Paper>
						</Box>
					</Stack>
					<Box flex={1} padding={1}>
						<Paper
							sx={{
								padding: 0.5,
								textAlign: 'center',
								backgroundColor: blueGrey[700]
							}}
						>
							<Typography variant="caption">
								{t('app:FinishMission.finalScore')}
							</Typography>
							<Typography variant="h6" color="secondary">
								{getScoreLetter(finishMissionResponse.meta.score?.finalScore)}
							</Typography>
						</Paper>
					</Box>
					{experienceReward}
					{cashReward}
					{weaponPartsReward}
					{loot}
				</Box>
			</Dialog>
		</>
	)
}

export default FinishMissionDialog
