import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Box,
	Button,
	Grid,
	Paper,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../redux'
import { login, resetPassword } from '../../redux/auth/thunks'
import {
	isValidConfirmPassword,
	isValidPassword
} from '../../util/validation/inputs'

const ResetPassword = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState(false)
	const [confirmPassword, setConfirmPassword] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (passwordError && isValidPassword(event.target.value)) {
			setPasswordError(false)
		}
		setPassword(event.target.value)
	}

	const handleConfirmPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (
			confirmPasswordError &&
			isValidConfirmPassword(event.target.value, password)
		) {
			setConfirmPasswordError(false)
		}
		setConfirmPassword(event.target.value)
	}

	const handleResetPasswordSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			if (!isValidPassword(password)) {
				setPasswordError(true)
			} else if (!isValidConfirmPassword(confirmPassword, password)) {
				setConfirmPasswordError(true)
			} else {
				setErrorText('')
				setIsLoading(true)
				const wrappedResetPasswordResult = await dispatch(
					resetPassword({
						handle: searchParams.get('handle') || '',
						newPassword: password,
						ticketToken: searchParams.get('ticketToken') || ''
					})
				)
				unwrapResult(wrappedResetPasswordResult) // unwrap to catch errors

				// Password reset successful, login automatically
				const wrappedLoginResult = await dispatch(
					login({
						email: searchParams.get('email') || '',
						password,
						persist: false
					})
				)
				unwrapResult(wrappedLoginResult) // unwrap to catch errors
				// Navigate to root
				navigate('/')
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsLoading(false)
		}
	}

	const handleBackToLoginClick = () => {
		navigate('/')
	}

	let error
	if (errorText) {
		error = <Alert severity="error">{errorText}</Alert>
	}

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<form onSubmit={handleResetPasswordSubmit}>
				<Grid container spacing={2} padding={2}>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<img
								alt="Mercenaries Online *i18n*"
								src="/assets/images/auth-logo.png"
								width="100%"
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<Paper elevation={1}>
								<Stack padding={2} spacing={2}>
									<Typography variant="h5">
										{t('app:Auth.resetPassword')}
									</Typography>
									{error}
									<TextField
										required
										variant="filled"
										label={t('app:Auth.password')}
										type="password"
										value={password}
										onChange={handlePasswordChange}
										disabled={isLoading}
										helperText={
											passwordError ? t('app:Auth.passwordError') : ''
										}
										error={passwordError}
									/>
									<TextField
										required
										variant="filled"
										label={t('app:Auth.confirmPassword')}
										type="password"
										value={confirmPassword}
										onChange={handleConfirmPasswordChange}
										disabled={isLoading}
										helperText={
											confirmPasswordError
												? t('app:Auth.confirmPasswordError')
												: ''
										}
										error={confirmPasswordError}
									/>
									<LoadingButton
										variant="contained"
										size="large"
										type="submit"
										loading={isLoading}
									>
										{t('app:Auth.resetPassword')}
									</LoadingButton>
									<Button
										fullWidth
										variant="contained"
										size="large"
										disabled={isLoading}
										onClick={handleBackToLoginClick}
									>
										{t('app:Auth.backToLogin')}
									</Button>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default ResetPassword
