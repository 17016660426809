import {
	ItemQuality,
	ProfileItem
} from '@allardvanderouw/mercenariesonline-types'
import { blue, green, grey, purple } from '@mui/material/colors'

export const getItemQualityColor = (item: ProfileItem) => {
	let qualityColor
	switch (item.quality) {
		case ItemQuality.Common:
			qualityColor = grey['300']
			break
		case ItemQuality.Epic:
			qualityColor = purple['400']
			break
		case ItemQuality.Rare:
			qualityColor = blue['700']
			break
		case ItemQuality.Uncommon:
			qualityColor = green['500']
			break
		default:
			qualityColor = grey['500']
			break
	}
	return qualityColor
}
