import {
	ProfileMission,
	ProfilesMissionStartResponseBody,
	SignedMissionCode
} from '@allardvanderouw/mercenariesonline-types'
import { ActiveDialog, DialogKey } from '../../../types'
import ArmoryDialog from './ArmoryDialog/ArmoryDialog'
import BitcoinFarmDialog from './BitcoinFarmDialog'
import BountyHubDialog from './BountyHubDialog'
import FinishMissionDialog from './FinishMissionDialog'
import FuelPumpDialog from './FuelPumpDialog'
import LeaderboardDialog from './LeaderboardDialog/LeaderboardDialog'
import MembershipDialog from './MembershipDialog/MembershipDialog'
import PlayMissionDialog from './PlayMissionDialog'
import SettingsDialog from './SettingsDialog/SettingsDialog'
import StartMissionDialog from './StartMissionDialog'
import WeaponPartsFactoryDialog from './WeaponPartsFactoryDialog'

interface DialogsProps {
	activeDialog: ActiveDialog
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const Dialogs: React.FC<DialogsProps> = ({ activeDialog, setActiveDialog }) => {
	// Keep play mission dialog mounted because of the iframe with the game client
	const playMissionDialog = (
		<PlayMissionDialog
			isOpen={activeDialog.key === DialogKey.PlayMission}
			setActiveDialog={setActiveDialog}
			startMissionPayload={
				activeDialog.data as ProfilesMissionStartResponseBody
			}
		/>
	)

	let dialog
	switch (activeDialog.key) {
		case DialogKey.Armory:
			dialog = <ArmoryDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.BitcoinFarm:
			dialog = <BitcoinFarmDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.BountyHub:
			dialog = <BountyHubDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.FuelPump:
			dialog = <FuelPumpDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.Leaderboard:
			dialog = <LeaderboardDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.Membership:
			dialog = <MembershipDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.Settings:
			dialog = <SettingsDialog setActiveDialog={setActiveDialog} />
			break
		case DialogKey.StartMission:
			dialog = (
				<StartMissionDialog
					setActiveDialog={setActiveDialog}
					profileMission={activeDialog.data as ProfileMission}
				/>
			)
			break
		case DialogKey.FinishMission:
			dialog = (
				<FinishMissionDialog
					setActiveDialog={setActiveDialog}
					missionReportCode={activeDialog.data as SignedMissionCode}
				/>
			)
			break
		case DialogKey.WeaponPartsFactory:
			dialog = <WeaponPartsFactoryDialog setActiveDialog={setActiveDialog} />
			break
		default:
			dialog = null
			break
	}

	return (
		<>
			{playMissionDialog}
			{dialog}
		</>
	)
}

export default Dialogs
