import { MembershipProduct } from '@allardvanderouw/mercenariesonline-types'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import { Box, Card, CardHeader, SvgIcon, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'

interface MembershipCardProps {
	product: MembershipProduct
	isLoading: boolean
	isDisabled: boolean
	duration: string
	price: string
	onSelect: (product: MembershipProduct) => void
}

const MembershipCard: React.FC<MembershipCardProps> = ({
	product,
	isLoading,
	isDisabled,
	duration,
	price,
	onSelect
}) => {
	const handleSelectClick = () => {
		onSelect(product)
	}

	return (
		<Card sx={{ width: '200px', backgroundColor: blueGrey[700] }}>
			<CardHeader
				sx={{ p: 1 }}
				title={
					<Box textAlign="center">
						<Typography variant="h4">{duration}</Typography>
						<Typography variant="subtitle2">{price}</Typography>
					</Box>
				}
			/>
			<LoadingButton
				fullWidth
				variant="contained"
				sx={{ borderRadius: 0 }}
				loading={isLoading}
				disabled={isDisabled}
				onClick={handleSelectClick}
				endIcon={
					<SvgIcon fontSize="inherit">
						<FontAwesomeIcon icon={faArrowRight} color="#fff" />
					</SvgIcon>
				}
			>
				{t('app:Generic.select')}
			</LoadingButton>
		</Card>
	)
}

export default MembershipCard
