import { ProfileMission } from '@allardvanderouw/mercenariesonline-types'
import {
	faCog,
	faDollarSign,
	faStar,
	faTint,
	faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import {
	Avatar,
	Box,
	Button,
	Card,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Rating,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey, red } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux'
import { startMission } from '../../../redux/profile/thunks'
import { ActiveDialog, DialogKey } from '../../../types'
import {
	getMissionRatingValue,
	getMissionTypeIcon,
	getMissionTypeText
} from '../../../util/profile/missions'
import DialogHeader from './DialogHeader'

interface StartMissionDialogProps {
	profileMission: ProfileMission
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const StartMissionDialog: React.FC<StartMissionDialogProps> = ({
	profileMission,
	setActiveDialog
}) => {
	const dispatch = useAppDispatch()
	const [isStarting, setIsStarting] = useState(false)
	const [errorText, setErrorText] = useState('')

	const fuel = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.fuel
	)
	const lootBag = useSelector(
		(state: RootState) => state.profile.entity.lootBag
	)
	const hasEnoughFuel = fuel >= profileMission.fuelCost
	const hasEmptyLootBag = lootBag.length === 0

	const handleClose = () => {
		setActiveDialog({ key: DialogKey.None })
	}

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleStartClick = async () => {
		if (!hasEnoughFuel) {
			setErrorText(t('app:Generic.notEnoughFuel'))
			return
		}
		if (!hasEmptyLootBag) {
			setErrorText(t('app:Generic.noEmptyLootBag'))
			return
		}
		try {
			setIsStarting(true)
			const missionID = profileMission.ID
			const wrappedResult = await dispatch(startMission({ missionID }))
			unwrapResult(wrappedResult) // unwrap to catch errors
			setActiveDialog({
				key: DialogKey.PlayMission,
				data: wrappedResult.payload
			})
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsStarting(false)
		}
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	let experienceReward
	if (profileMission.experienceReward) {
		experienceReward = (
			<>
				<Divider />
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faUserPlus} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:StartMission.experienceReward')}
						</Typography>
					}
					subheader={profileMission.experienceReward.toLocaleString()}
				/>
			</>
		)
	}

	let cashReward
	if (profileMission.cashReward) {
		cashReward = (
			<>
				<Divider />
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faDollarSign} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:StartMission.cashReward')}
						</Typography>
					}
					subheader={profileMission.cashReward.toLocaleString()}
				/>
			</>
		)
	}

	let weaponPartsReward
	if (profileMission.weaponPartsReward) {
		weaponPartsReward = (
			<>
				<Divider />
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faCog} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:StartMission.weaponPartsReward')}
						</Typography>
					}
					subheader={profileMission.weaponPartsReward.toLocaleString()}
				/>
			</>
		)
	}

	return (
		<>
			{errorDialog}
			<Dialog open={true} fullWidth onClose={handleClose}>
				<DialogHeader
					activeDialogKey={DialogKey.StartMission}
					setActiveDialog={setActiveDialog}
				/>
				<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>
					<Stack width="100%" direction="row">
						<Box>
							<Stack
								width="100%"
								sx={{
									width: 275,
									zIndex: 1000,
									display: 'flex',
									backgroundColor: blueGrey[800],
									borderRight: `4px solid ${blueGrey[500]}`
								}}
							>
								<Stack flexGrow={1} padding={1} spacing={1}>
									<Card sx={{ backgroundColor: blueGrey[700] }}>
										<CardHeader
											sx={{ p: 1 }}
											avatar={
												<Avatar
													variant="rounded"
													sx={{ backgroundColor: red['A700'] }}
												>
													<SvgIcon fontSize="medium">
														<FontAwesomeIcon
															icon={getMissionTypeIcon(
																profileMission.typeKey,
																profileMission.isStory
															)}
															color="#fff"
														/>
													</SvgIcon>
												</Avatar>
											}
											title={
												<Typography variant="subtitle2">
													{getMissionTypeText(
														profileMission.typeKey,
														profileMission.tier,
														profileMission.isStory
													)}
												</Typography>
											}
											subheader={
												<Rating
													readOnly
													size="small"
													value={getMissionRatingValue(profileMission.tier)}
													precision={0.5}
													icon={
														<SvgIcon fontSize="inherit">
															<FontAwesomeIcon icon={faStar} />
														</SvgIcon>
													}
													emptyIcon={
														<SvgIcon fontSize="inherit">
															<FontAwesomeIcon icon={faStar} />
														</SvgIcon>
													}
												/>
											}
										/>
										{experienceReward}
										{cashReward}
										{weaponPartsReward}
									</Card>
									<Card sx={{ backgroundColor: blueGrey[700] }}>
										<CardHeader
											sx={{ p: 1 }}
											avatar={
												<Avatar
													variant="rounded"
													sx={{ backgroundColor: blueGrey[500] }}
												>
													<SvgIcon fontSize="medium">
														<FontAwesomeIcon icon={faTint} color="#fff" />
													</SvgIcon>
												</Avatar>
											}
											title={
												<Typography variant="subtitle2">
													{t('app:StartMission.fuelRequired')}
												</Typography>
											}
											subheader={
												<Typography
													variant="body2"
													color={hasEnoughFuel ? 'textSecondary' : 'error'}
												>
													{profileMission.fuelCost.toLocaleString()}
												</Typography>
											}
										/>
										<LoadingButton
											fullWidth
											variant="contained"
											sx={{ borderRadius: 0 }}
											loading={isStarting}
											onClick={handleStartClick}
										>
											{t('app:Generic.start')}
										</LoadingButton>
									</Card>
								</Stack>
							</Stack>
						</Box>
						<Box
							flexGrow={1}
							sx={{
								background: 'url("/assets/images/auth-background.jpg")',
								backgroundSize: 'cover',
								backgroundPosition: 'center'
							}}
						></Box>
					</Stack>
				</Box>
			</Dialog>
		</>
	)
}

export default StartMissionDialog
