import {
	MembershipProduct,
	UsersMembershipStartTransactionResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { isAfter } from 'date-fns'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux'
import { startMembershipTransaction } from '../../../../redux/user/thunks/membership'
import MembershipBenefit from './MembershipBenefit'
import MembershipCard from './MembershipCard'

const MembershipStepNone: React.FC = () => {
	const dispatch = useAppDispatch()
	const [selectedProduct, setSelectedProduct] = useState<
		MembershipProduct | undefined
	>()
	const [isLoading, setIsLoading] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [startTransactionResponse, setStartTransactionResponse] = useState<
		UsersMembershipStartTransactionResponseBody | undefined
	>()
	const membershipPeriods = useSelector(
		(state: RootState) => state.user.membershipPeriods
	)

	useEffect(() => {
		if (startTransactionResponse?.redirectTo) {
			// Navigate to Stripe
			window.location.href = startTransactionResponse.redirectTo
		}
	}, [startTransactionResponse])

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleSelectProduct = useCallback(
		(product: MembershipProduct) => {
			const start = async () => {
				try {
					setSelectedProduct(product)
					setIsLoading(true)
					const wrappedResult = await dispatch(
						startMembershipTransaction({ product })
					)
					unwrapResult(wrappedResult) // unwrap to catch errors
					setStartTransactionResponse(
						wrappedResult.payload as UsersMembershipStartTransactionResponseBody
					)
					setSelectedProduct(undefined)
					setIsLoading(false)
				} catch (error: any) {
					setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
					setSelectedProduct(undefined)
					setIsLoading(false)
				}
			}
			start()
		},
		[dispatch]
	)

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	let activeMembership
	if (
		membershipPeriods &&
		membershipPeriods.length > 0 &&
		isAfter(
			new Date(membershipPeriods[membershipPeriods.length - 1].endDate),
			new Date()
		)
	) {
		activeMembership = (
			<Box padding={1} paddingBottom={0}>
				<Alert severity="success">
					<strong>{t('app:Membership.Benefits.bigThanks')}</strong>
					<br />
					{t('app:Membership.activeMembership')}{' '}
					<strong>
						{new Date(
							membershipPeriods[membershipPeriods.length - 1].endDate
						).toLocaleString()}
					</strong>
					.
				</Alert>
			</Box>
		)
	}
	if (
		membershipPeriods &&
		membershipPeriods.length > 0 &&
		isAfter(
			new Date(),
			new Date(membershipPeriods[membershipPeriods.length - 1].endDate)
		)
	) {
		activeMembership = (
			<Box padding={1} paddingBottom={0}>
				<Alert severity="info">
					<strong>{t('app:Membership.Benefits.bigThanks')}</strong>
					<br />
					{t('app:Membership.activeMembershipExpired')}
				</Alert>
			</Box>
		)
	}

	return (
		<>
			{errorDialog}
			<Box flexGrow={1}>
				<Stack
					width="100%"
					height="100%"
					sx={{
						display: 'flex',
						backgroundColor: blueGrey[800],
						borderRight: `4px solid ${blueGrey[500]}`
					}}
				>
					{activeMembership}
					<Stack direction="row" padding={1} spacing={1}>
						<Box flexGrow={1}>
							<MembershipCard
								product={MembershipProduct.Days30}
								isLoading={
									isLoading && selectedProduct === MembershipProduct.Days30
								}
								isDisabled={
									isLoading && selectedProduct !== MembershipProduct.Days30
								}
								duration={t('app:Membership.Product.30days')}
								price="$ 3,00"
								onSelect={handleSelectProduct}
							/>
						</Box>
						<Box flexGrow={1}>
							<MembershipCard
								product={MembershipProduct.Days90}
								isLoading={
									isLoading && selectedProduct === MembershipProduct.Days90
								}
								isDisabled={
									isLoading && selectedProduct !== MembershipProduct.Days90
								}
								duration={t('app:Membership.Product.90days')}
								price="$ 7,50"
								onSelect={handleSelectProduct}
							/>
						</Box>
						<Box flexGrow={1}>
							<MembershipCard
								product={MembershipProduct.Days365}
								isLoading={
									isLoading && selectedProduct === MembershipProduct.Days365
								}
								isDisabled={
									isLoading && selectedProduct !== MembershipProduct.Days365
								}
								duration={t('app:Membership.Product.365days')}
								price="$ 24,00"
								onSelect={handleSelectProduct}
							/>
						</Box>
					</Stack>
					<Box padding={1}>
						<Divider />
					</Box>
					<Box padding={1}>
						<List disablePadding>
							<MembershipBenefit
								benefit={t('app:Membership.Benefits.bigThanks')}
							/>
							<MembershipBenefit
								benefit={t('app:Membership.Benefits.equipSpecialWeapon')}
							/>
							<MembershipBenefit
								benefit={t('app:Membership.Benefits.equipHeadArmor')}
							/>
							<MembershipBenefit
								benefit={t(
									'app:Membership.Benefits.additionalFuelPumpCapacity'
								)}
							/>
							<MembershipBenefit
								benefit={t('app:Membership.Benefits.unlimitedBounties')}
							/>
							<MembershipBenefit
								benefit={t('app:Membership.Benefits.additionalMissionRewards')}
							/>
							<MembershipBenefit
								benefit={t('app:Membership.Benefits.additionalMissionLoot')}
							/>
							<ListItem disablePadding>
								<ListItemIcon>
									<SvgIcon fontSize="small">
										<FontAwesomeIcon icon={faAsterisk} color="#fff" />
									</SvgIcon>
								</ListItemIcon>
								<ListItemText
									sx={{ fontStyle: 'italic', color: 'text.secondary' }}
									primary={t('app:Membership.Benefits.permanentBenefits')}
								/>
							</ListItem>
						</List>
					</Box>
				</Stack>
			</Box>
		</>
	)
}

export default MembershipStepNone
