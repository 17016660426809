import { getProfileRankFromProfileExperience } from '@allardvanderouw/mercenariesonline-commons'
import { ProfileStoryMission } from '@allardvanderouw/mercenariesonline-types'
import { faBookDead } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Card,
	CardHeader,
	LinearProgress,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { getAreaText } from '../../../util/profile/missions'

const StoryCard: React.FC = () => {
	const story = useSelector((state: RootState) => state.profile.entity.story)
	const hero = useSelector((state: RootState) => state.profile.entity.hero)

	const currentRank = getProfileRankFromProfileExperience(hero.experience)

	const allMissions = Object.values(story.missions) as ProfileStoryMission[]
	const totalMissionsLength = allMissions.length
	const completedMissionsLength = allMissions.filter(
		(mission) => mission.completed
	).length
	const progress = Math.round(
		(completedMissionsLength / totalMissionsLength) * 100
	)

	let subheader = ''
	const nextMission = allMissions.find((mission) => !mission.completed)
	if (nextMission) {
		if (nextMission.requiredLevel > currentRank.level) {
			subheader = `${t('app:Profile.storyReachLevel')} ${nextMission.requiredLevel}`
		} else if (nextMission.requiredLevel <= currentRank.level) {
			subheader = `${t('app:Profile.storyCompleteMission')} ${getAreaText(nextMission.tier)}`
		}
	}

	return (
		<Card sx={{ backgroundColor: blueGrey[700] }}>
			<CardHeader
				sx={{ p: 1 }}
				avatar={
					<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
						<SvgIcon fontSize="medium">
							<FontAwesomeIcon icon={faBookDead} color="#fff" />
						</SvgIcon>
					</Avatar>
				}
				title={
					<Typography variant="subtitle2">
						{t('app:Profile.storyProgressPart1')} {completedMissionsLength}{' '}
						{t('app:Profile.storyProgressPart2')} {totalMissionsLength}{' '}
						{t('app:Profile.storyProgressPart3')}
					</Typography>
				}
				subheader={subheader}
			/>
			<LinearProgress
				color="secondary"
				value={progress}
				variant="determinate"
			/>
		</Card>
	)
}

export default StoryCard
