import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'
interface MessageDialogProps {
	close: () => void
	closeButtonText: string
	descriptionText: string
	isOpen: boolean
	titleText: string
}

const MessageDialog: React.FC<MessageDialogProps> = ({
	close,
	closeButtonText,
	descriptionText,
	isOpen,
	titleText
}) => {
	let description
	if (descriptionText) {
		const descriptionTextLines = descriptionText
			.split(/\r?\n/)
			.map((descriptionTextLine, key) => (
				<span key={`descriptionline-${key}`}>
					{descriptionTextLine}
					<br />
				</span>
			))
		description = (
			<DialogContent>
				<DialogContentText>{descriptionTextLines}</DialogContentText>
			</DialogContent>
		)
	}

	return (
		<Dialog open={isOpen} onClose={close}>
			<DialogTitle>{titleText}</DialogTitle>
			{description}
			<DialogActions>
				<Box sx={{ color: 'text.secondary', mr: 1 }}>
					<Button onClick={close} variant="outlined" color="inherit">
						{closeButtonText}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	)
}

export default MessageDialog
