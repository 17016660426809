import {
	faCog,
	faCogs,
	faCrown,
	faDollarSign,
	faGasPump,
	faGavel,
	faIndustry,
	faLaptopHouse,
	faListCheck,
	faPlay,
	faQuestion,
	faRankingStar,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Box,
	Button,
	Chip,
	CircularProgress,
	DialogTitle,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import {
	blueGrey,
	cyan,
	deepOrange,
	green,
	grey,
	lightBlue,
	orange,
	purple,
	red
} from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { RootState } from '../../../redux'
import { ActiveDialog, DialogKey, ProfileStateStatus } from '../../../types'

interface DialogHeaderProps {
	activeDialogKey: DialogKey
	setActiveDialog: (activeDialog: ActiveDialog) => void
	hideAssets?: boolean
}

const DialogHeader: React.FC<DialogHeaderProps> = ({
	activeDialogKey,
	setActiveDialog,
	hideAssets
}) => {
	const status = useSelector((state: RootState) => state.profile.status)
	const [, setSearchParams] = useSearchParams()

	const fuel = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.fuel
	)

	const cash = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.cash
	)

	const weaponParts = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.weaponParts
	)

	const handleCloseClick = () => {
		setSearchParams(undefined)
		setActiveDialog({ key: DialogKey.None })
	}

	let title: string = activeDialogKey
	let icon: IconDefinition = faQuestion
	let iconColor: string = grey['A700']
	switch (activeDialogKey) {
		case DialogKey.Armory:
			title = t('app:Armory.title')
			icon = faGavel
			iconColor = cyan['800']
			break
		case DialogKey.BitcoinFarm:
			title = t('app:Profile.bitcoinFarm')
			icon = faLaptopHouse
			iconColor = green['A700']
			break
		case DialogKey.BountyHub:
			title = t('app:BountyHub.title')
			icon = faListCheck
			iconColor = deepOrange['800']
			break
		case DialogKey.FuelPump:
			title = t('app:Profile.fuelPump')
			icon = faGasPump
			iconColor = orange['A700']
			break
		case DialogKey.Leaderboard:
			title = t('app:Leaderboard.title')
			icon = faRankingStar
			iconColor = purple['A700']
			break
		case DialogKey.WeaponPartsFactory:
			title = t('app:Profile.weaponPartsFactory')
			icon = faIndustry
			iconColor = lightBlue['A700']
			break
		case DialogKey.StartMission:
			title = t('app:StartMission.title')
			icon = faPlay
			iconColor = red['A700']
			break
		case DialogKey.FinishMission:
			title = t('app:FinishMission.title')
			icon = faPlay
			iconColor = red['A700']
			break
		case DialogKey.Membership:
			title = t('app:Membership.title')
			icon = faCrown
			iconColor = orange['A700']
			break
		case DialogKey.Settings:
			title = t('app:Settings.title')
			icon = faCogs
			iconColor = orange['A700']
			break
		default:
			break
	}

	let loadingIndicator
	if (status === ProfileStateStatus.Pending) {
		loadingIndicator = (
			<Box pr={1} display="flex">
				<CircularProgress size={24} />
			</Box>
		)
	}

	let assets
	if (!hideAssets) {
		assets = (
			<>
				<Box pl={1}>
					<Chip
						sx={{ backgroundColor: blueGrey[500] }}
						label={`${fuel.toLocaleString()} / 100`}
						avatar={
							<Avatar sx={{ backgroundColor: blueGrey['800'] }}>
								<SvgIcon fontSize="inherit">
									<FontAwesomeIcon icon={faGasPump} color="#fff" />
								</SvgIcon>
							</Avatar>
						}
					/>
				</Box>
				<Box pl={1}>
					<Chip
						sx={{ backgroundColor: blueGrey[500] }}
						label={cash.toLocaleString()}
						avatar={
							<Avatar sx={{ backgroundColor: blueGrey['800'] }}>
								<SvgIcon fontSize="inherit">
									<FontAwesomeIcon icon={faDollarSign} color="#fff" />
								</SvgIcon>
							</Avatar>
						}
					/>
				</Box>
				<Box pl={1}>
					<Chip
						sx={{ backgroundColor: blueGrey[500] }}
						label={weaponParts.toLocaleString()}
						avatar={
							<Avatar sx={{ backgroundColor: blueGrey['800'] }}>
								<SvgIcon fontSize="inherit">
									<FontAwesomeIcon icon={faCog} color="#fff" />
								</SvgIcon>
							</Avatar>
						}
					/>
				</Box>
			</>
		)
	}

	return (
		<DialogTitle sx={{ p: 0 }}>
			<Box
				p={1}
				sx={{
					backgroundColor: blueGrey[800],
					borderTop: `4px solid ${blueGrey[500]}`,
					borderLeft: `4px solid ${blueGrey[500]}`,
					borderRight: `4px solid ${blueGrey[500]}`
				}}
			>
				<Stack flexDirection="row" justifyContent="space-between">
					<Stack flexDirection="row" alignItems="center">
						<Box pr={1}>
							<Avatar variant="rounded" sx={{ backgroundColor: iconColor }}>
								<SvgIcon fontSize="medium">
									<FontAwesomeIcon icon={icon} color="#fff" />
								</SvgIcon>
							</Avatar>
						</Box>
						<Box pr={1}>
							<Typography variant="subtitle2">{title}</Typography>
						</Box>
						{loadingIndicator}
					</Stack>
					<Stack flexDirection="row" alignItems="center">
						{assets}
						<Box pl={2}>
							<Button
								fullWidth
								variant="contained"
								onClick={handleCloseClick}
								color="error"
							>
								{t('app:Generic.close')}
							</Button>
						</Box>
					</Stack>
				</Stack>
			</Box>
		</DialogTitle>
	)
}

export default DialogHeader
