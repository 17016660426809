import { Profile } from '@allardvanderouw/mercenariesonline-types'
import { createSlice } from '@reduxjs/toolkit'
import { ProfileStateStatus } from '../../types'
import * as authThunks from '../auth/thunks'
import * as thunks from './thunks'

const initialState = {
	status: ProfileStateStatus.Initial,
	entity: {} as Profile
}

const slice = createSlice({
	name: 'profile',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//
		// Auth thunks
		//
		builder.addCase(authThunks.login.fulfilled, (state, action) => {
			if (action.payload?.profile) {
				state.status = ProfileStateStatus.Idle
				state.entity = action.payload.profile
			}
		})
		builder.addCase(authThunks.autoLogin.fulfilled, (state, action) => {
			if (action.payload?.profile) {
				state.status = ProfileStateStatus.Idle
				state.entity = action.payload.profile
			}
		})
		//
		// Profile thunks
		//
		builder.addCase(thunks.createProfile.fulfilled, (state, action) => {
			state.entity = action.payload
		})
		//
		// Bitcoin Farm thunks
		//
		builder.addCase(thunks.upgradeBitcoinFarm.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.liquidAssets.cash = profileUpdates.cash
				state.entity.fixedAssets.bitcoinFarm = profileUpdates.bitcoinFarm
			}
		})
		builder.addCase(thunks.collectBitcoinFarm.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.liquidAssets.cash = profileUpdates.cash
				state.entity.fixedAssets.bitcoinFarm = profileUpdates.bitcoinFarm
				state.entity.stats = profileUpdates.stats
			}
		})
		//
		// Bounty hub thunks
		//
		builder.addCase(thunks.aquireBountyHub.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.bountyHub.activeBounty = profileUpdates.bounty
			}
		})
		builder.addCase(thunks.completeBountyHub.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.bountyHub = profileUpdates.bountyHub
				state.entity.hero.fame = profileUpdates.fame
				state.entity.inventory = profileUpdates.inventory
				state.entity.stats = profileUpdates.stats
			}
		})
		//
		// Weapon Parts Factory thunks
		//
		builder.addCase(
			thunks.upgradeWeaponPartsFactory.fulfilled,
			(state, action) => {
				const { profileUpdates } = action.payload
				if (state) {
					state.entity.liquidAssets.cash = profileUpdates.cash
					state.entity.fixedAssets.weaponPartsFactory =
						profileUpdates.weaponPartsFactory
				}
			}
		)
		builder.addCase(
			thunks.collectWeaponPartsFactory.fulfilled,
			(state, action) => {
				const { profileUpdates } = action.payload
				if (state) {
					state.entity.liquidAssets.weaponParts = profileUpdates.weaponParts
					state.entity.fixedAssets.weaponPartsFactory =
						profileUpdates.weaponPartsFactory
					state.entity.stats = profileUpdates.stats
				}
			}
		)
		//
		// Fuel Pump thunks
		//
		builder.addCase(thunks.collectFuelPump.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.liquidAssets.fuel = profileUpdates.fuel
				state.entity.fixedAssets.fuelPump = profileUpdates.fuelPump
			}
		})
		//
		// Missions thunks
		//
		builder.addCase(thunks.startMission.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.liquidAssets.fuel = profileUpdates.fuel
				state.entity.missions = profileUpdates.missions
				state.entity.stats = profileUpdates.stats
			}
		})
		builder.addCase(thunks.finishMission.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.hero.experience = profileUpdates.experience
				state.entity.liquidAssets.cash = profileUpdates.cash
				state.entity.liquidAssets.weaponParts = profileUpdates.weaponParts
				state.entity.missions = profileUpdates.missions
				state.entity.inventory = profileUpdates.inventory
				state.entity.lootBag = profileUpdates.lootBag
				state.entity.stats = profileUpdates.stats
				state.entity.bountyHub.activeBounty = profileUpdates.bounty
				state.entity.story = profileUpdates.story
			}
		})
		//
		// Armory thunks
		//
		builder.addCase(
			thunks.collectArmoryItemFromLootBag.fulfilled,
			(state, action) => {
				const { profileUpdates } = action.payload
				if (state) {
					state.entity.inventory = profileUpdates.inventory
					state.entity.lootBag = profileUpdates.lootBag
				}
			}
		)
		builder.addCase(
			thunks.equipArmoryItemFromInventory.fulfilled,
			(state, action) => {
				const { profileUpdates } = action.payload
				if (state) {
					state.entity.equipment = profileUpdates.equipment
					state.entity.inventory = profileUpdates.inventory
				}
			}
		)
		builder.addCase(
			thunks.moveArmoryItemFromInventory.fulfilled,
			(state, action) => {
				const { profileUpdates } = action.payload
				if (state) {
					state.entity.inventory = profileUpdates.inventory
				}
			}
		)
		builder.addCase(thunks.salvageArmoryItem.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.liquidAssets.weaponParts = profileUpdates.weaponParts
				if (profileUpdates.inventory) {
					state.entity.inventory = profileUpdates.inventory
				}
				if (profileUpdates.lootBag) {
					state.entity.lootBag = profileUpdates.lootBag
				}
				state.entity.stats = profileUpdates.stats
			}
		})
		builder.addCase(thunks.sellArmoryItem.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				state.entity.liquidAssets.cash = profileUpdates.cash
				if (profileUpdates.inventory) {
					state.entity.inventory = profileUpdates.inventory
				}
				if (profileUpdates.lootBag) {
					state.entity.lootBag = profileUpdates.lootBag
				}
				state.entity.stats = profileUpdates.stats
			}
		})
		builder.addCase(thunks.upgradeArmoryItem.fulfilled, (state, action) => {
			const { profileUpdates } = action.payload
			if (state) {
				if (profileUpdates.cash !== undefined) {
					state.entity.liquidAssets.cash = profileUpdates.cash
				}
				if (profileUpdates.weaponParts !== undefined) {
					state.entity.liquidAssets.weaponParts = profileUpdates.weaponParts
				}
				if (profileUpdates.equipment) {
					state.entity.equipment = profileUpdates.equipment
				}
				if (profileUpdates.inventory) {
					state.entity.inventory = profileUpdates.inventory
				}
			}
		})
		//
		// Update state status
		//
		builder.addMatcher(
			(action) =>
				action.type.startsWith('profiles/') && action.type.endsWith('/pending'),
			(state, action) => {
				state.status = ProfileStateStatus.Pending
			}
		)
		builder.addMatcher(
			(action) =>
				(action.type.startsWith('profiles/') &&
					action.type.endsWith('/fulfilled')) ||
				action.type.endsWith('/rejected'),
			(state, action) => {
				state.status = ProfileStateStatus.Idle
			}
		)
	}
})

export default slice.reducer
