import { User } from '@allardvanderouw/mercenariesonline-types'

export const isMembershipActive = (user: User): boolean => {
	// Loop through membership periods and check if any of them are active
	for (const period of user.membershipPeriods) {
		if (new Date(period.endDate) > new Date()) {
			return true
		}
	}
	return false
}
