import { ProfileItem } from '@allardvanderouw/mercenariesonline-types'
import { Box } from '@mui/material'
import { ItemSource } from '../../../../types'
import ProfileItemFragment from './ProfileItemFragment'

interface MissionLootItemFragmentProps {
	lootBagItem: ProfileItem
	divider?: boolean
}

const MissionLootItemFragment: React.FC<MissionLootItemFragmentProps> = ({
	lootBagItem
}) => {
	return (
		<Box p={0.5}>
			<ProfileItemFragment
				item={lootBagItem}
				size="60px"
				source={ItemSource.MissionLoot}
			/>
		</Box>
	)
}

export default MissionLootItemFragment
