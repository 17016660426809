import { faListCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Box,
	ButtonBase,
	Card,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey, deepOrange, grey } from '@mui/material/colors'
import { t } from 'i18next'
import { LatLngExpression } from 'leaflet'
import { Marker } from 'react-leaflet-marker'
import { ActiveDialog, DialogKey } from '../../../../types'

interface BountyHubMarkerProps {
	position: LatLngExpression
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const BountyHubMarker: React.FC<BountyHubMarkerProps> = ({
	position,
	setActiveDialog
}) => {
	return (
		<div style={{ position: 'absolute' }}>
			<Marker position={position} size={[80, 100]}>
				<ButtonBase
					onClick={() => setActiveDialog({ key: DialogKey.BountyHub })}
				>
					<Card
						sx={{
							width: 80,
							height: 100,
							backgroundColor: blueGrey[700]
						}}
					>
						<Box padding={1}>
							<Avatar
								variant="rounded"
								sx={{
									backgroundColor: deepOrange['800'],
									width: 64,
									height: 64
								}}
							>
								<SvgIcon fontSize="large" sx={{ color: 'white' }}>
									<FontAwesomeIcon icon={faListCheck} />
								</SvgIcon>
							</Avatar>
						</Box>
						<Box
							height={20}
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{ backgroundColor: grey[300] }}
						>
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={0.5}
							>
								<Typography variant="caption" color={blueGrey[800]}>
									{t('app:BountyHub.title')}
								</Typography>
							</Stack>
						</Box>
					</Card>
				</ButtonBase>
			</Marker>
		</div>
	)
}

export default BountyHubMarker
