import { BASE_MISSIONS_MAP } from '@allardvanderouw/mercenariesonline-commons'
import { Tier } from '@allardvanderouw/mercenariesonline-types'
import { useTheme } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { ImageOverlay, MapContainer, useMapEvents } from 'react-leaflet'
import { MarkerLayer } from 'react-leaflet-marker'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { ActiveDialog } from '../../../types'
import ArmoryMarker from './Markers/ArmoryMarker'
import BitcoinFarmMarker from './Markers/BitcoinFarmMarker'
import BountyHubMarker from './Markers/BountyHubMarker'
import FuelPumpMarker from './Markers/FuelPumpMarker'
import LeaderboardMarker from './Markers/LeaderboardMarker'
import MissionMarker from './Markers/MissionMarker'
import WeaponPartsFactoryMarker from './Markers/WeaponPartsFactoryMarker'
import ZoneMarker from './Markers/ZoneMarker'

interface MapProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const MapEvents = () => {
	useMapEvents({
		click: (e) => {
			console.log(
				`Clicked at latitude ${e.latlng.lat.toFixed(
					2
				)}, longitude ${e.latlng.lng.toFixed(2)}`
			)
		}
	})
	return null
}

const Map: React.FC<MapProps> = ({ setActiveDialog }) => {
	const theme = useTheme()

	const missions = useSelector(
		(state: RootState) => state.profile.entity.missions
	)

	const missionMarkers = missions.map((mission) => (
		<MissionMarker
			key={mission.ID}
			mission={mission}
			setActiveDialog={setActiveDialog}
		/>
	))

	const hasFirstMission = missions.some(
		(mission) => mission.isStory && mission.tier === Tier.T10
	)

	let firstMissionIndicators = <></>
	if (hasFirstMission) {
		firstMissionIndicators = (
			<>
				<ZoneMarker
					label="»"
					variant="h2"
					position={[1.455, 1.48]}
					color={theme.palette.secondary.light}
				/>
				<ZoneMarker
					label="«"
					variant="h2"
					position={[1.455, 1.82]}
					color={theme.palette.secondary.light}
				/>
			</>
		)
	}

	// Center at the green zone
	let initialCenter: LatLngExpression = [1.15, 1.15]
	if (hasFirstMission) {
		// Center at first mission
		initialCenter = [1.35, 1.65]
	}

	return (
		<MapContainer
			center={initialCenter}
			zoom={10.25}
			zoomControl={false}
			minZoom={10.25}
			maxZoom={10.25}
			style={{ width: '100%', top: 0, bottom: 0, position: 'absolute' }}
			attributionControl={false}
			maxBounds={[
				[0, 0],
				[2370 / 1000, 5120 / 1000]
			]}
		>
			<MapEvents />
			<ImageOverlay
				zIndex={-1}
				url="/assets/map.png"
				bounds={[
					[0, 0],
					[2370 / 1000, 5120 / 1000]
				]}
			/>
			<MarkerLayer>
				{/* Arrow indicators for first mission */}
				{firstMissionIndicators}
				{/* Zone markers (labels on map) */}
				<ZoneMarker
					label="The Green Zone"
					variant="h4"
					position={[1.15, 1.15]}
				/>
				<ZoneMarker
					label="Arkon"
					variant="h5"
					position={[1.35, 1.65]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T10)?.requiredLevel}
				/>
				<ZoneMarker
					label="Blade"
					variant="h5"
					position={[1.68, 1.66]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T15)?.requiredLevel}
				/>
				<ZoneMarker
					label="Cobalt"
					variant="h5"
					position={[1.4, 2.15]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T20)?.requiredLevel}
				/>
				<ZoneMarker
					label="Dune"
					variant="h5"
					position={[1.91, 2.27]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T25)?.requiredLevel}
				/>
				<ZoneMarker
					label="Eden"
					variant="h5"
					position={[1.54, 2.98]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T30)?.requiredLevel}
				/>
				<ZoneMarker
					label="Falcon"
					variant="h5"
					position={[1.05, 2.42]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T35)?.requiredLevel}
				/>
				<ZoneMarker
					label="Gorr"
					variant="h5"
					position={[0.71, 3.08]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T40)?.requiredLevel}
				/>
				<ZoneMarker
					label="Hydra"
					variant="h5"
					position={[0.89, 3.46]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T45)?.requiredLevel}
				/>
				<ZoneMarker
					label="Inferno"
					variant="h5"
					position={[1.24, 3.37]}
					requiredLevel={BASE_MISSIONS_MAP.get(Tier.T50)?.requiredLevel}
				/>
				{/* Locations markers */}
				<ArmoryMarker position={[1.5, 0.9]} setActiveDialog={setActiveDialog} />
				<BountyHubMarker
					position={[1.28, 0.96]}
					setActiveDialog={setActiveDialog}
				/>
				<BitcoinFarmMarker
					position={[1, 1]}
					setActiveDialog={setActiveDialog}
				/>
				<FuelPumpMarker
					position={[1.4, 1.15]}
					setActiveDialog={setActiveDialog}
				/>
				<LeaderboardMarker
					position={[1.07, 1.4]}
					setActiveDialog={setActiveDialog}
				/>
				<WeaponPartsFactoryMarker
					position={[1.1, 1.6]}
					setActiveDialog={setActiveDialog}
				/>
				{/* Mission markers */}
				{missionMarkers}
			</MarkerLayer>
		</MapContainer>
	)
}

export default Map
