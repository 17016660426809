import { Box, Dialog, Stack } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { useCallback, useState } from 'react'
import {
	ActiveDialog,
	DialogKey,
	SettingsDialogSubMenu
} from '../../../../types'
import DialogHeader from '../DialogHeader'
import SettingsMainMenu from './SettingsMainMenu'
import SettingsSubMenuChangeEmail from './SettingsSubMenuChangeEmail'
import SettingsSubMenuChangePassword from './SettingsSubMenuChangePassword'
import SettingsSubMenuDeleteAccount from './SettingsSubMenuDeleteAccount'

interface SettingsDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({ setActiveDialog }) => {
	const [subMenu, setSubMenu] = useState(SettingsDialogSubMenu.None)

	const handleClose = useCallback(() => {
		setActiveDialog({ key: DialogKey.None })
	}, [setActiveDialog])

	let content
	if (subMenu === SettingsDialogSubMenu.ChangeEmail) {
		content = (
			<SettingsSubMenuChangeEmail
				setActiveDialog={setActiveDialog}
				setSubMenu={setSubMenu}
			/>
		)
	} else if (subMenu === SettingsDialogSubMenu.ChangePassword) {
		content = (
			<SettingsSubMenuChangePassword
				setActiveDialog={setActiveDialog}
				setSubMenu={setSubMenu}
			/>
		)
	} else if (subMenu === SettingsDialogSubMenu.DeleteAccount) {
		content = (
			<SettingsSubMenuDeleteAccount
				setActiveDialog={setActiveDialog}
				setSubMenu={setSubMenu}
			/>
		)
	} else {
		content = <SettingsMainMenu setSubMenu={setSubMenu} />
	}

	return (
		<Dialog
			sx={{ width: '100%' }}
			open={true}
			maxWidth={false}
			onClose={handleClose}
		>
			<DialogHeader
				activeDialogKey={DialogKey.Settings}
				setActiveDialog={setActiveDialog}
				hideAssets={true}
			/>
			<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>
				<Stack width="100%" direction="row">
					<Box>
						<Stack
							width="100%"
							sx={{
								width: 350,
								zIndex: 1000,
								display: 'flex',
								backgroundColor: blueGrey[800],
								borderRight: `4px solid ${blueGrey[500]}`
							}}
						>
							<Stack flexGrow={1} padding={1} spacing={1}>
								{content}
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Dialog>
	)
}

export default SettingsDialog
