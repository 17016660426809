import { VariantType } from 'notistack'

export enum AppEventType {
	SnackbarNotification = 'snackbarnotification'
}

export const sendSnackbarNotification = (
	message: string,
	variant: VariantType = 'default'
) => {
	window.dispatchEvent(
		new CustomEvent(AppEventType.SnackbarNotification, {
			detail: { message, options: { variant } }
		})
	)
}
