import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'

interface MembershipBenefitProps {
	benefit: string
}

const MembershipBenefit: React.FC<MembershipBenefitProps> = ({ benefit }) => {
	return (
		<ListItem disablePadding>
			<ListItemIcon>
				<SvgIcon fontSize="small">
					<FontAwesomeIcon icon={faPlus} color="#fff" />
				</SvgIcon>
			</ListItemIcon>
			<ListItemText primary={benefit} />
		</ListItem>
	)
}

export default MembershipBenefit
