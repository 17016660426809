import { getCollectableBitcoinFarmCash } from '@allardvanderouw/mercenariesonline-commons'
import { faDollarSign, faLaptopHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Box,
	ButtonBase,
	Card,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey, green, grey } from '@mui/material/colors'
import { t } from 'i18next'
import { LatLngExpression } from 'leaflet'
import { useCallback, useEffect, useState } from 'react'
import { Marker } from 'react-leaflet-marker'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { ActiveDialog, DialogKey } from '../../../../types'

interface FixedAssetMarkerProps {
	position: LatLngExpression
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const FixedAssetMarker: React.FC<FixedAssetMarkerProps> = ({
	position,
	setActiveDialog
}) => {
	const [collectableCash, setCollectableCash] = useState(0)

	const bitcoinFarm = useSelector(
		(state: RootState) => state.profile.entity.fixedAssets.bitcoinFarm
	)

	const refreshCollectableCash = useCallback(() => {
		const newCollectableCash = getCollectableBitcoinFarmCash(bitcoinFarm)
		setCollectableCash(newCollectableCash)
	}, [setCollectableCash, bitcoinFarm])

	useEffect(() => {
		const interval = setInterval(() => {
			refreshCollectableCash()
		}, 1000)
		refreshCollectableCash()
		return () => {
			clearInterval(interval)
		}
	}, [refreshCollectableCash])

	let content
	if (bitcoinFarm.level === 0) {
		content = (
			<Typography variant="caption" color={blueGrey[700]}>
				{t('app:Generic.forSale')}
			</Typography>
		)
	} else {
		content = (
			<>
				<SvgIcon fontSize="inherit">
					<FontAwesomeIcon color={blueGrey[800]} icon={faDollarSign} />
				</SvgIcon>
				<Typography variant="caption" color={blueGrey[800]}>
					{collectableCash.toLocaleString()}
				</Typography>
			</>
		)
	}

	return (
		<div style={{ position: 'absolute' }}>
			<Marker position={position} size={[80, 100]}>
				<ButtonBase
					onClick={() => setActiveDialog({ key: DialogKey.BitcoinFarm })}
				>
					<Card
						sx={{
							width: 80,
							height: 100,
							backgroundColor: blueGrey[700]
						}}
					>
						<Box padding={1}>
							<Avatar
								variant="rounded"
								sx={{
									backgroundColor: green['A700'],
									width: 64,
									height: 64
								}}
							>
								<SvgIcon fontSize="large" sx={{ color: 'white' }}>
									<FontAwesomeIcon icon={faLaptopHouse} />
								</SvgIcon>
							</Avatar>
						</Box>
						<Box
							height={20}
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{ backgroundColor: grey[300] }}
						>
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={0.5}
							>
								{content}
							</Stack>
						</Box>
					</Card>
				</ButtonBase>
			</Marker>
		</div>
	)
}

export default FixedAssetMarker
