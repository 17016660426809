import {
	ItemSlot,
	ProfileInventorySlot
} from '@allardvanderouw/mercenariesonline-types'
import { CardHeader, Divider, Typography } from '@mui/material'
import { t } from 'i18next'
import { ItemSource } from '../../../../types'
import {
	getItemDescription,
	getItemSlotDescription
} from '../../../../util/items/get-item-description'
import ProfileItemFragment from './ProfileItemFragment'

interface EquipmentItemFragmentProps {
	divider?: boolean
	equipmentItem: ProfileInventorySlot
	itemSlot: ItemSlot
}

const EquipmentItemFragment: React.FC<EquipmentItemFragmentProps> = ({
	divider,
	equipmentItem,
	itemSlot
}) => {
	let dividerComponent
	if (divider) {
		dividerComponent = <Divider />
	}

	let description
	if (equipmentItem) {
		description = getItemDescription(equipmentItem.itemID)
	} else {
		description = t('app:Generic.empty')
	}

	const itemFragment = (
		<ProfileItemFragment
			key={`${itemSlot}-${equipmentItem?.ID}-${equipmentItem?.level}`}
			item={equipmentItem}
			size="40px"
			source={ItemSource.Equipment}
		/>
	)

	return (
		<>
			<CardHeader
				sx={{ p: 1 }}
				avatar={itemFragment}
				title={
					<Typography variant="subtitle2">
						{getItemSlotDescription(itemSlot)}
					</Typography>
				}
				subheader={description}
			/>
			{dividerComponent}
		</>
	)
}

export default EquipmentItemFragment
