import { LoadingButton } from '@mui/lab'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useAppDispatch } from '../../../../redux'
import { deleteAccount } from '../../../../redux/user/thunks'
import { ActiveDialog, SettingsDialogSubMenu } from '../../../../types'
import { setSessionToken } from '../../../../util/session'

interface SettingsSubMenuDeleteAccountProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
	setSubMenu: (subMenu: SettingsDialogSubMenu) => void
}

const SettingsSubMenuDeleteAccount: React.FC<
	SettingsSubMenuDeleteAccountProps
> = ({ setActiveDialog, setSubMenu }) => {
	const dispatch = useAppDispatch()
	const [errorText, setErrorText] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [password, setPassword] = useState('')
	const [confirmText, setConfirmText] = useState('')
	const [confirmTextError, setConfirmTextError] = useState(false)

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}

	const handleConfirmTextChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (confirmTextError && event.target.value === 'permanently delete') {
			setConfirmTextError(false)
		}
		setConfirmText(event.target.value)
	}

	const onDeleteAccountClick = async () => {
		try {
			if (confirmText !== 'permanently delete') {
				setConfirmTextError(true)
			} else {
				setIsLoading(true)
				const wrappedResult = await dispatch(deleteAccount({ password }))
				unwrapResult(wrappedResult) // unwrap to catch errors
				// Reload after success
				setSessionToken(null)
				window.location.replace('/')
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsLoading(false)
		}
	}

	const onBackToSettingsClick = () => {
		setSubMenu(SettingsDialogSubMenu.None)
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<>
			{errorDialog}
			<Typography>{t('app:Settings.DeleteAccount.description')}</Typography>
			<TextField
				autoFocus
				required
				label={t('app:Settings.DeleteAccount.password')}
				variant="filled"
				type="password"
				value={password}
				onChange={handlePasswordChange}
				disabled={isLoading}
			/>
			<TextField
				required
				label={t('app:Settings.DeleteAccount.permanentlyDelete')}
				variant="filled"
				value={confirmText}
				onChange={handleConfirmTextChange}
				disabled={isLoading}
				helperText={
					confirmTextError
						? t('app:Settings.DeleteAccount.confirmTextError')
						: ''
				}
				error={confirmTextError}
			/>
			<LoadingButton
				fullWidth
				loading={isLoading}
				variant="contained"
				onClick={onDeleteAccountClick}
			>
				{t('app:Settings.deleteAccount')}
			</LoadingButton>
			<Button fullWidth variant="contained" onClick={onBackToSettingsClick}>
				{t('app:Generic.back')}
			</Button>
		</>
	)
}

export default SettingsSubMenuDeleteAccount
