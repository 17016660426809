import {
	ProfilesBountyHubAquireResponseBody,
	ProfilesBountyHubCompleteRequestBody,
	ProfilesBountyHubCompleteResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const aquireBountyHub = createAsyncThunk(
	'profiles/aquireBountyHub',
	async (): Promise<ProfilesBountyHubAquireResponseBody> => {
		const response = await callFunction('/functions/profiles/bountyHub/aquire')
		return response
	}
)

export const completeBountyHub = createAsyncThunk(
	'profiles/completeBountyHub',
	async (
		payload: ProfilesBountyHubCompleteRequestBody
	): Promise<ProfilesBountyHubCompleteResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/bountyHub/complete',
			payload
		)
		return response
	}
)
