import {
	Box,
	CircularProgress,
	Grid,
	Paper,
	Stack,
	Typography
} from '@mui/material'
import { t } from 'i18next'

interface LoadingProps {
	message: string
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<Grid container spacing={2} padding={2}>
				<Grid container justifyContent="center">
					<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
						<img
							alt={t('app:Generic.title')}
							src="/assets/images/auth-logo.png"
							width="100%"
						/>
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
						<Paper elevation={1}>
							<Stack
								direction="column"
								padding={2}
								spacing={2}
								alignItems="center"
							>
								<CircularProgress />
								<Typography variant="h6">{message}</Typography>
							</Stack>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default Loading
