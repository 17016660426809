import {
	BaseMissionTypeKey,
	Tier
} from '@allardvanderouw/mercenariesonline-types'
import {
	faBookDead,
	faCrosshairs,
	faSkull,
	faSkullCrossbones
} from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'

export const getAreaText = (tier: Tier) => {
	switch (tier) {
		case Tier.T10:
			return 'Arkon'
		case Tier.T15:
			return 'Blade'
		case Tier.T20:
			return 'Cobalt'
		case Tier.T25:
			return 'Dune'
		case Tier.T30:
			return 'Eden'
		case Tier.T35:
			return 'Falcon'
		case Tier.T40:
			return 'Gorr'
		case Tier.T45:
			return 'Hydra'
		case Tier.T50:
			return 'Inferno'
	}
}

export const getMissionTypeText = (
	missionTypeKey: BaseMissionTypeKey,
	tier?: Tier,
	isStory?: boolean
) => {
	if (isStory && tier) {
		const areaText = getAreaText(tier)
		return `${t('app:Profile.MissionTypes.Story')} ${areaText}`
	}
	switch (missionTypeKey) {
		case BaseMissionTypeKey.Assassination:
			return t('app:Profile.MissionTypes.Assassination')
		case BaseMissionTypeKey.Elimination:
			return t('app:Profile.MissionTypes.Elimination')
		case BaseMissionTypeKey.Extermination:
			return t('app:Profile.MissionTypes.Extermination')
	}
}

export const getMissionTypeIcon = (
	missionTypeKey: BaseMissionTypeKey,
	isStory = false
) => {
	if (isStory) {
		return faBookDead
	}
	switch (missionTypeKey) {
		case BaseMissionTypeKey.Assassination:
			return faCrosshairs
		case BaseMissionTypeKey.Elimination:
			return faSkullCrossbones
		case BaseMissionTypeKey.Extermination:
			return faSkull
	}
}

export const getMissionRatingValue = (tier: Tier): number => {
	switch (tier) {
		case Tier.T50:
			return 5
		case Tier.T45:
			return 4.5
		case Tier.T40:
			return 4
		case Tier.T35:
			return 3.5
		case Tier.T30:
			return 3
		case Tier.T25:
			return 2.5
		case Tier.T20:
			return 2
		case Tier.T15:
			return 1.5
		default:
			return 1
	}
}

export const getTierNumber = (tier: Tier): number => {
	switch (tier) {
		case Tier.T10:
			return 1
		case Tier.T15:
			return 1.5
		case Tier.T20:
			return 2
		case Tier.T25:
			return 2.5
		case Tier.T30:
			return 3
		case Tier.T35:
			return 3.5
		case Tier.T40:
			return 4
		case Tier.T45:
			return 4.5
		case Tier.T50:
			return 5
	}
}
