import {
	ProfileBountyHub,
	ProfileMission
} from '@allardvanderouw/mercenariesonline-types'
import { getTierNumber } from '../profile/missions'

export const isMissionBountyObjective = (
	mission: ProfileMission,
	activeBounty: ProfileBountyHub['activeBounty']
) => {
	if (mission.isStory) {
		return false
	}

	let value = false
	if (activeBounty) {
		value = activeBounty.objectives.some((objective) => {
			if (activeBounty) {
				const isSameMissionType = objective.missionType === mission.typeKey
				const activeBountyTierNumber = getTierNumber(activeBounty.tier)
				const profileMissionTierNumber = getTierNumber(mission.tier)
				const isValidTier = profileMissionTierNumber >= activeBountyTierNumber
				const isObjectiveCompleted =
					objective.quantityCompleted === objective.quantityRequired
				if (isSameMissionType && isValidTier && !isObjectiveCompleted) {
					return true
				}
			}
			return false
		})
	}
	return value
}
