import { amber, blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const getTheme = () => {
	const mode = 'dark'
	const theme = createTheme({
		palette: {
			background: {
				default: blueGrey[900],
				paper: blueGrey[800]
			},
			primary: {
				light: blueGrey[300],
				main: blueGrey[500],
				dark: blueGrey[700],
				contrastText: '#fff'
			},
			secondary: {
				light: amber[300],
				main: amber[500],
				dark: amber[700],
				contrastText: '#fff'
			},
			mode
		},
		typography: {
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"'
			].join(',')
		}
	})
	return theme
}

export default getTheme
