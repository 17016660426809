import { ProfilesLeaderboardGetStatsResponseBody } from '@allardvanderouw/mercenariesonline-types'
import {
	Box,
	Button,
	Card,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Stack,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../redux'
import { getLeaderboardStats } from '../../../../redux/profile/thunks/leaderboard'
import { ActiveDialog, DialogKey } from '../../../../types'
import DialogHeader from '../DialogHeader'
import LeaderboardPositionFragment from './LeaderboardPositionFragment'

interface LeaderboardDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const LeaderboardDialog: React.FC<LeaderboardDialogProps> = ({
	setActiveDialog
}) => {
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [stats, setStats] = useState<
		ProfilesLeaderboardGetStatsResponseBody | undefined
	>()

	const handleClose = useCallback(() => {
		setActiveDialog({ key: DialogKey.None })
	}, [setActiveDialog])

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	useEffect(() => {
		const loadLeaderboard = async () => {
			try {
				setIsLoading(true)
				const wrappedResult = await dispatch(getLeaderboardStats())
				unwrapResult(wrappedResult) // unwrap to catch errors
				setStats(
					wrappedResult.payload as ProfilesLeaderboardGetStatsResponseBody
				)
				setIsLoading(false)
			} catch (error: any) {
				setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
				setIsLoading(false)
			}
		}
		loadLeaderboard()
	}, [dispatch, setActiveDialog])

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	let content
	if (isLoading || !stats) {
		content = (
			<Stack direction="column" padding={2} spacing={2} alignItems="center">
				<CircularProgress />
				<Typography variant="h6">{t('app:Generic.loading')}</Typography>
			</Stack>
		)
	} else {
		const experienceLeaderboardPositions = stats.meta.experienceLeaderboard.map(
			(position, index) => (
				<LeaderboardPositionFragment
					key={`experience-leaderboard-${index}`}
					divider={index !== stats.meta.experienceLeaderboard.length - 1}
					position={position}
					type="experience"
					rank={index + 1}
				/>
			)
		)
		const fameLeaderboardPositions = stats.meta.fameLeaderboard.map(
			(position, index) => (
				<LeaderboardPositionFragment
					key={`fame-leaderboard-${index}`}
					divider={index !== stats.meta.fameLeaderboard.length - 1}
					position={position}
					type="fame"
					rank={index + 1}
				/>
			)
		)

		content = (
			<>
				<Stack width="100%" direction="row">
					<Box flexGrow={1}>
						<Stack
							width="320px"
							height="100%"
							sx={{
								display: 'flex',
								backgroundColor: blueGrey[800],
								borderRight: `4px solid ${blueGrey[500]}`
							}}
						>
							<Stack flexGrow={1} padding={1} spacing={1}>
								<Card sx={{ backgroundColor: blueGrey[700] }}>
									<CardHeader
										sx={{ p: 1 }}
										title={
											<Box textAlign="center">
												<Typography variant="subtitle2">
													{t('app:Leaderboard.yourExperienceRanking')}:
												</Typography>
												<Typography variant="h4">
													#{' '}
													{
														stats.meta
															.profileExperienceLeaderboardPositionNumber
													}
												</Typography>
											</Box>
										}
									/>
								</Card>
								<Card sx={{ backgroundColor: blueGrey[700] }}>
									<CardHeader
										sx={{ p: 1 }}
										title={
											<Typography variant="subtitle2">
												{t('app:Leaderboard.experienceTop1000')}
											</Typography>
										}
									/>
									<Divider />
									{experienceLeaderboardPositions}
								</Card>
							</Stack>
						</Stack>
					</Box>
					<Box flexGrow={1}>
						<Stack
							width="320px"
							height="100%"
							sx={{
								display: 'flex',
								backgroundColor: blueGrey[800],
								borderRight: `4px solid ${blueGrey[500]}`
							}}
						>
							<Stack flexGrow={1} padding={1} spacing={1}>
								<Card sx={{ backgroundColor: blueGrey[700] }}>
									<CardHeader
										sx={{ p: 1 }}
										title={
											<Box textAlign="center">
												<Typography variant="subtitle2">
													{t('app:Leaderboard.yourFameRanking')}:
												</Typography>
												<Typography variant="h4">
													# {stats.meta.profileFameLeaderboardPositionNumber}
												</Typography>
											</Box>
										}
									/>
								</Card>
								<Card sx={{ backgroundColor: blueGrey[700] }}>
									<CardHeader
										sx={{ p: 1 }}
										title={
											<Typography variant="subtitle2">
												{t('app:Leaderboard.fameTop1000')}
											</Typography>
										}
									/>
									<Divider />
									{fameLeaderboardPositions}
								</Card>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</>
		)
	}

	return (
		<>
			{errorDialog}
			<Dialog
				sx={{ width: '100%' }}
				open={true}
				maxWidth={false}
				onClose={handleClose}
			>
				<DialogHeader
					activeDialogKey={DialogKey.Leaderboard}
					setActiveDialog={setActiveDialog}
					hideAssets={true}
				/>
				<Box
					sx={{
						border: `4px solid ${blueGrey[500]}`,
						overflowY: 'auto',
						overflowX: 'hidden'
					}}
				>
					{content}
				</Box>
			</Dialog>
		</>
	)
}

export default LeaderboardDialog
