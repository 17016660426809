import { faReddit } from '@fortawesome/free-brands-svg-icons'
import {
	faBoxes,
	faCogs,
	faCrosshairs,
	faCrown,
	faExclamation,
	faQuestion,
	faRightFromBracket
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Stack,
	SvgIcon,
	Tooltip,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useAppDispatch } from '../../../redux'
import { logout } from '../../../redux/auth/thunks'
import { ActiveDialog, DialogKey } from '../../../types'
import MessageDialog from '../../Shared/MessageDialog'
import CashCard from './CashCard'
import FuelCard from './FuelCard'
import ProfileCard from './ProfileCard'
import StoryCard from './StoryCard'
import WeaponPartsCard from './WeaponPartsCard'

interface HudProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const Hud: React.FC<HudProps> = ({ setActiveDialog }) => {
	const dispatch = useAppDispatch()
	const [isLoggingOut, setIsLoggingOut] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handleLogoutClick = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			setIsLoggingOut(true)
			const wrappedResult = await dispatch(logout())
			unwrapResult(wrappedResult) // unwrap to catch errors
			// Logout successful, reload page to reset state
			window.location.replace('/')
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsLoggingOut(false)
		}
	}

	const handleMessageDialogClose = () => {
		setErrorText('')
	}

	const onMembershipClick = () => {
		setActiveDialog({ key: DialogKey.Membership })
	}

	const onSettingsClick = () => {
		setActiveDialog({ key: DialogKey.Settings })
	}

	// TODO: Implement daily challenge
	const hasDailyChallenge = false
	let dailyChallenge
	if (hasDailyChallenge) {
		dailyChallenge = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faExclamation} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Profile.dailyChallenge')}
						</Typography>
					}
				/>
				<Divider />
				<List dense>
					<ListItem>
						<ListItemAvatar>
							<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
								<SvgIcon>
									<FontAwesomeIcon icon={faCrosshairs} color="#fff" />
								</SvgIcon>
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Assassination" secondary="0 / 3 completed" />
					</ListItem>
					<ListItem>
						<ListItemAvatar>
							<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
								<SvgIcon>
									<FontAwesomeIcon icon={faBoxes} color="#fff" />
								</SvgIcon>
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary="Search & Collect"
							secondary="0 / 1 completed"
						/>
					</ListItem>
				</List>
				<Button
					sx={{ borderRadius: 0 }}
					color="primary"
					variant="contained"
					fullWidth
					disableElevation
					onClick={() => alert('test')}
					disabled
				>
					{t('app:Profile.collectRewards')}
				</Button>
			</Card>
		)
	}

	return (
		<>
			<MessageDialog
				close={handleMessageDialogClose}
				closeButtonText={t('app:Generic.close')}
				descriptionText={errorText}
				isOpen={Boolean(errorText)}
				titleText={t('app:Generic.errorOccurred')}
			/>
			<Paper
				square
				sx={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					right: 0,
					width: 280,
					zIndex: 1000,
					display: 'flex',
					borderLeft: `4px solid ${blueGrey[500]}`
				}}
			>
				<Stack width="100%">
					<Box display="flex" justifyContent="space-around" pt={1}>
						<Tooltip title={t('app:Generic.membership')}>
							<IconButton onClick={onMembershipClick}>
								<SvgIcon>
									<FontAwesomeIcon icon={faCrown} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('app:Generic.settings')}>
							<IconButton onClick={onSettingsClick}>
								<SvgIcon>
									<FontAwesomeIcon icon={faCogs} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('app:Generic.reddit')}>
							<IconButton
								component="a"
								target="_blank"
								href="https://www.reddit.com/r/MercenariesOnlineGame/"
							>
								<SvgIcon>
									<FontAwesomeIcon icon={faReddit} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('app:Generic.help')}>
							<IconButton
								component="a"
								target="_blank"
								href="https://mercenaries-online-game.fandom.com/wiki/Mercenaries_Online_Game_Wiki"
							>
								<SvgIcon>
									<FontAwesomeIcon icon={faQuestion} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('app:Generic.logout')}>
							<IconButton onClick={handleLogoutClick} disabled={isLoggingOut}>
								<SvgIcon>
									<FontAwesomeIcon icon={faRightFromBracket} />
								</SvgIcon>
							</IconButton>
						</Tooltip>
					</Box>
					<Stack flexGrow={1} padding={1} spacing={1}>
						<ProfileCard />
						<StoryCard />
						<FuelCard setActiveDialog={setActiveDialog} />
						<CashCard setActiveDialog={setActiveDialog} />
						<WeaponPartsCard setActiveDialog={setActiveDialog} />
						{dailyChallenge}
						<Button
							component="a"
							target="_blank"
							href="https://www.reddit.com/r/MercenariesOnlineGame/"
							endIcon={
								<SvgIcon>
									<FontAwesomeIcon icon={faReddit} />
								</SvgIcon>
							}
							variant="contained"
						>
							{t('app:Generic.reportAnIssue')}
						</Button>
					</Stack>
				</Stack>
			</Paper>
		</>
	)
}

export default Hud
