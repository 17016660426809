import { FunctionError } from '../errors/function-error'
import { getFunctionErrorDescription } from '../errors/get-function-error-description'
import { NetworkError } from '../errors/network-error'
import { ServerError } from '../errors/server-error'
import { getSessionToken } from '../session'

export const callFunction = async (path: string, requestBody: any = {}) => {
	let response
	try {
		let headers: HeadersInit = {
			'Content-Type': 'application/json'
		}
		const sessionToken = getSessionToken()
		if (sessionToken) {
			headers = {
				...headers,
				Authorization: `Bearer ${sessionToken}`
			}
		}
		response = await fetch(path, {
			method: 'POST',
			headers,
			body: JSON.stringify(requestBody)
		})
	} catch (error) {
		throw new NetworkError()
	}
	if (!response || !response.ok) {
		if (response.status === 500) {
			throw new ServerError()
		}
		let responseMessage = ''
		try {
			const responseBody = await response.json()
			responseMessage = responseBody.message
		} catch (error) {
			// Do nothing
		}
		const functionErrorMessage = getFunctionErrorDescription(responseMessage)
		throw new FunctionError(functionErrorMessage)
	}
	// Response is ok
	if (response.status === 204) {
		return
	}
	return await response.json()
}
