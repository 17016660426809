import {
	ProfileItem,
	ProfilesBountyHubCompleteRequestBody
} from '@allardvanderouw/mercenariesonline-types'
import {
	faCheckCircle,
	faCircle,
	faFaceGrinStars,
	faListCheck,
	faStar
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import {
	Avatar,
	Box,
	Button,
	ButtonBase,
	Card,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Rating,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey, red } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { isSameDay } from 'date-fns'
import { t } from 'i18next'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux'
import {
	aquireBountyHub,
	completeBountyHub
} from '../../../redux/profile/thunks/bountyHub'
import { ActiveDialog, DialogKey } from '../../../types'
import { isBountyCompleted } from '../../../util/profile/bountyHub'
import {
	getMissionRatingValue,
	getMissionTypeIcon,
	getMissionTypeText
} from '../../../util/profile/missions'
import { isMembershipActive } from '../../../util/user'
import MissionLootItemFragment from './ArmoryDialog/MissionLootItemFragment'
import DialogHeader from './DialogHeader'

interface BountyHubDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const BountyHubDialog: React.FC<BountyHubDialogProps> = ({
	setActiveDialog
}) => {
	const dispatch = useAppDispatch()
	const [isAquiring, setIsAquiring] = useState(false)
	const [isCompleting, setIsCompleting] = useState(false)
	const [selectedRewardItemID, setSelectedRewardItemID] = useState<
		ProfileItem['ID'] | null
	>(null)
	const [errorText, setErrorText] = useState('')

	const bountyHub = useSelector(
		(state: RootState) => state.profile.entity.bountyHub
	)
	const user = useSelector((state: RootState) => state.user)

	const fame = useSelector((state: RootState) => state.profile.entity.hero.fame)

	const handleClose = () => {
		setActiveDialog({ key: DialogKey.None })
	}

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleAquireClick = async () => {
		if (bountyHub.activeBounty) {
			setErrorText(t('app:BountyHub.bountyAlreadyActive'))
			return
		}
		const hasActiveMembership = isMembershipActive(user)
		const hasBeenCompletedToday = isSameDay(
			new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000),
			new Date(
				new Date(bountyHub.lastCompletedDate).getTime() +
					new Date(bountyHub.lastCompletedDate).getTimezoneOffset() * 60000
			)
		)
		if (!hasActiveMembership && hasBeenCompletedToday) {
			setErrorText(t('app:BountyHub.bountyAlreadyCompletedToday'))
			return
		}

		try {
			setIsAquiring(true)
			const wrappedResult = await dispatch(aquireBountyHub())
			unwrapResult(wrappedResult) // unwrap to catch errors
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsAquiring(false)
		}
	}

	const handleCompleteClick = async () => {
		if (!bountyHub.activeBounty) {
			setErrorText(t('app:BountyHub.cannotCompleteBecauseOfNoActiveBounty'))
			return
		}
		if (!isBountyCompleted(bountyHub.activeBounty)) {
			setErrorText(t('app:BountyHub.cannotCompleteBecauseOfNotCompleted'))
			return
		}
		if (!selectedRewardItemID) {
			setErrorText(t('app:BountyHub.cannotCompleteBecauseOfNoRewardSelected'))
			return
		}
		try {
			setIsCompleting(true)
			const payload: ProfilesBountyHubCompleteRequestBody = {
				itemID: selectedRewardItemID
			}
			const wrappedResult = await dispatch(completeBountyHub(payload))
			unwrapResult(wrappedResult) // unwrap to catch errors
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsCompleting(false)
		}
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	let aquireCard
	if (!bountyHub.activeBounty) {
		aquireCard = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faListCheck} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:BountyHub.bounty')}
						</Typography>
					}
					subheader={t('app:BountyHub.noActiveBounty')}
				/>
				<LoadingButton
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					loading={isAquiring}
					onClick={handleAquireClick}
				>
					{t('app:BountyHub.aquire')}
				</LoadingButton>
			</Card>
		)
	}

	let activeBountyCard
	if (bountyHub.activeBounty) {
		const objectives = bountyHub.activeBounty.objectives.map((objective) => {
			const objectiveIcon = getMissionTypeIcon(objective.missionType)
			const objectiveText = getMissionTypeText(objective.missionType)
			return (
				<Fragment key={`objective-${objective.missionType}`}>
					<Divider />
					<CardHeader
						sx={{ p: 1 }}
						avatar={
							<Avatar variant="rounded" sx={{ backgroundColor: red['A700'] }}>
								<SvgIcon fontSize="medium">
									<FontAwesomeIcon icon={objectiveIcon} color="#fff" />
								</SvgIcon>
							</Avatar>
						}
						title={<Typography variant="subtitle2">{objectiveText}</Typography>}
						subheader={`${objective.quantityCompleted.toLocaleString()} / ${objective.quantityRequired.toLocaleString()}`}
					/>
				</Fragment>
			)
		})

		activeBountyCard = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faListCheck} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Generic.difficulty')}
						</Typography>
					}
					subheader={
						<Rating
							readOnly
							size="small"
							value={getMissionRatingValue(bountyHub.activeBounty.tier)}
							precision={0.5}
							icon={
								<SvgIcon fontSize="inherit">
									<FontAwesomeIcon icon={faStar} />
								</SvgIcon>
							}
							emptyIcon={
								<SvgIcon fontSize="inherit">
									<FontAwesomeIcon icon={faStar} />
								</SvgIcon>
							}
						/>
					}
				/>
				{objectives}
			</Card>
		)
	}

	let completeBountyCard
	if (bountyHub.activeBounty && isBountyCompleted(bountyHub.activeBounty)) {
		const possibleRewards = bountyHub.activeBounty.possibleRewards.map(
			(possibleReward) => {
				const possibleRewardIcon =
					selectedRewardItemID === possibleReward.ID ? faCheckCircle : faCircle
				return (
					<ButtonBase
						key={`possibleReward-${possibleReward.ID}`}
						onClick={() => setSelectedRewardItemID(possibleReward.ID)}
					>
						<Stack direction="column" alignItems="center">
							<MissionLootItemFragment
								key={`item-${possibleReward.ID}`}
								lootBagItem={possibleReward}
							/>
							<SvgIcon>
								<FontAwesomeIcon icon={possibleRewardIcon} />
							</SvgIcon>
						</Stack>
					</ButtonBase>
				)
			}
		)

		completeBountyCard = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					title={
						<Typography variant="subtitle2">
							{t('app:BountyHub.selectReward')}
						</Typography>
					}
				/>
				<Divider />
				<CardHeader
					sx={{ p: 1 }}
					title={
						<Stack flexDirection="row" justifyContent="center">
							{possibleRewards}
						</Stack>
					}
				/>
				<LoadingButton
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					loading={isCompleting}
					onClick={handleCompleteClick}
				>
					{t('app:BountyHub.complete')}
				</LoadingButton>
			</Card>
		)
	}

	return (
		<>
			{errorDialog}
			<Dialog open={true} fullWidth onClose={handleClose}>
				<DialogHeader
					activeDialogKey={DialogKey.BountyHub}
					setActiveDialog={setActiveDialog}
				/>
				<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>
					<Stack width="100%" direction="row">
						<Box>
							<Stack
								width="100%"
								sx={{
									width: 275,
									zIndex: 1000,
									display: 'flex',
									backgroundColor: blueGrey[800],
									borderRight: `4px solid ${blueGrey[500]}`
								}}
							>
								<Stack flexGrow={1} padding={1} spacing={1}>
									<Card sx={{ backgroundColor: blueGrey[700] }}>
										<CardHeader
											sx={{ p: 1 }}
											avatar={
												<Avatar
													variant="rounded"
													sx={{ backgroundColor: blueGrey[500] }}
												>
													<SvgIcon fontSize="medium">
														<FontAwesomeIcon
															icon={faFaceGrinStars}
															color="#fff"
														/>
													</SvgIcon>
												</Avatar>
											}
											title={
												<Typography variant="subtitle2">
													{t('app:Profile.fame')}
												</Typography>
											}
											subheader={fame.toLocaleString()}
										/>
									</Card>
									{aquireCard}
									{activeBountyCard}
									{completeBountyCard}
								</Stack>
							</Stack>
						</Box>
						<Box
							flexGrow={1}
							sx={{
								background: 'url("/assets/images/bounty-hub.jpg")',
								backgroundSize: 'cover',
								backgroundPosition: 'center'
							}}
						></Box>
					</Stack>
				</Box>
			</Dialog>
		</>
	)
}

export default BountyHubDialog
