import { Box, Dialog, Stack } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { useCallback } from 'react'
import { ActiveDialog, DialogKey } from '../../../../types'
import DialogHeader from '../DialogHeader'
import Equipment from './Equipment'
import Inventory from './Inventory'
import LootBag from './LootBag'

interface ArmoryDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const ArmoryDialog: React.FC<ArmoryDialogProps> = ({ setActiveDialog }) => {
	const handleClose = useCallback(() => {
		setActiveDialog({ key: DialogKey.None })
	}, [setActiveDialog])

	return (
		<Dialog
			sx={{ width: '100%' }}
			open={true}
			maxWidth={false}
			onClose={handleClose}
		>
			<DialogHeader
				activeDialogKey={DialogKey.Armory}
				setActiveDialog={setActiveDialog}
			/>
			<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>
				<Stack width="100%" direction="row">
					<Box>
						<Stack
							width="100%"
							height="100%"
							sx={{
								width: 275,
								display: 'flex',
								backgroundColor: blueGrey[800],
								borderRight: `4px solid ${blueGrey[500]}`
							}}
						>
							<Stack flexGrow={1} padding={1} spacing={1}>
								<Equipment />
							</Stack>
						</Stack>
					</Box>
					<Box flexGrow={1}>
						<Stack
							width="100%"
							height="100%"
							sx={{
								display: 'flex',
								backgroundColor: blueGrey[800],
								borderRight: `4px solid ${blueGrey[500]}`
							}}
						>
							<Stack padding={1} spacing={1}>
								<LootBag />
								<Inventory />
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Dialog>
	)
}

export default ArmoryDialog
