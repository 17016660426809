import {
	FUEL_PUMP_TIME_UNTIL_CAPPED_COLLECT_IN_MILLISECONDS,
	getCollectableFuelPumpFuel,
	getFuelPumpCapacity
} from '@allardvanderouw/mercenariesonline-commons'
import { faClock, faTint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import {
	Avatar,
	Box,
	Button,
	Card,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	LinearProgress,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux'
import { collectFuelPump } from '../../../redux/profile/thunks/fuelPump'
import { ActiveDialog, DialogKey } from '../../../types'
import { getTimeFromMilliseconds } from '../../../util/date/get-time'
import DialogHeader from './DialogHeader'

interface FuelPumpDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const FuelPumpDialog: React.FC<FuelPumpDialogProps> = ({ setActiveDialog }) => {
	const dispatch = useAppDispatch()
	const [isCollecting, setIsCollecting] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [collectableFuel, setCollectableFuel] = useState(0)
	const [timeTillCapped, setTimeTillCapped] = useState('')

	const membershipPeriods = useSelector(
		(state: RootState) => state.user.membershipPeriods
	)
	const fuelPump = useSelector(
		(state: RootState) => state.profile.entity.fixedAssets.fuelPump
	)
	const fuel = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.fuel
	)

	const fuelPumpCapacity = getFuelPumpCapacity(membershipPeriods)
	const isPlayerFull = fuel === 100

	const handleClose = () => {
		setActiveDialog({ key: DialogKey.None })
	}

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handleCollectClick = async () => {
		if (collectableFuel <= 0) {
			setErrorText(t('app:FuelPump.noCollectableFuel'))
			return
		}
		if (isPlayerFull) {
			setErrorText(t('app:FuelPump.playerFull'))
			return
		}
		try {
			setIsCollecting(true)
			const wrappedResult = await dispatch(collectFuelPump())
			unwrapResult(wrappedResult) // unwrap to catch errors
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsCollecting(false)
		}
	}

	const refreshCollectableFuel = useCallback(() => {
		const newCollectableFuel = getCollectableFuelPumpFuel(
			fuelPump,
			membershipPeriods
		)
		setCollectableFuel(newCollectableFuel)
	}, [fuelPump, membershipPeriods])

	const refreshTimeTillCapped = useCallback(() => {
		const timeAfterLastCollectInMilliseconds =
			new Date().valueOf() - new Date(fuelPump.lastCollectedDate).valueOf()
		const timeTillCappedInMilliseconds =
			FUEL_PUMP_TIME_UNTIL_CAPPED_COLLECT_IN_MILLISECONDS -
			timeAfterLastCollectInMilliseconds
		const newTimeTillCapped =
			timeTillCappedInMilliseconds > 0
				? getTimeFromMilliseconds(timeTillCappedInMilliseconds)
				: '0:00:00'
		setTimeTillCapped(newTimeTillCapped)
	}, [setTimeTillCapped, fuelPump])

	useEffect(() => {
		const interval = setInterval(() => {
			refreshCollectableFuel()
			refreshTimeTillCapped()
		}, 1000)
		refreshCollectableFuel()
		refreshTimeTillCapped()
		return () => {
			clearInterval(interval)
		}
	}, [refreshCollectableFuel, refreshTimeTillCapped])

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<>
			{errorDialog}
			<Dialog open={true} fullWidth onClose={handleClose}>
				<DialogHeader
					activeDialogKey={DialogKey.FuelPump}
					setActiveDialog={setActiveDialog}
				/>
				<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>
					<Stack width="100%" direction="row">
						<Box>
							<Stack
								width="100%"
								sx={{
									width: 275,
									zIndex: 1000,
									display: 'flex',
									backgroundColor: blueGrey[800],
									borderRight: `4px solid ${blueGrey[500]}`
								}}
							>
								<Stack flexGrow={1} padding={1} spacing={1}>
									<Card sx={{ backgroundColor: blueGrey[700] }}>
										<CardHeader
											sx={{ p: 1 }}
											avatar={
												<Avatar
													variant="rounded"
													sx={{ backgroundColor: blueGrey[500] }}
												>
													<SvgIcon fontSize="medium">
														<FontAwesomeIcon icon={faTint} color="#fff" />
													</SvgIcon>
												</Avatar>
											}
											title={
												<Typography variant="subtitle2">
													{t('app:FuelPump.currentFuel')}
												</Typography>
											}
											subheader={`${fuel.toLocaleString()} / 100 ${
												isPlayerFull ? `(${t('app:Generic.full')})` : ''
											}`}
										/>
										<LinearProgress
											color="secondary"
											value={fuel}
											variant="determinate"
										/>
									</Card>
									<Card sx={{ backgroundColor: blueGrey[700] }}>
										<CardHeader
											sx={{ p: 1 }}
											avatar={
												<Avatar
													variant="rounded"
													sx={{ backgroundColor: blueGrey[500] }}
												>
													<SvgIcon fontSize="medium">
														<FontAwesomeIcon icon={faTint} color="#fff" />
													</SvgIcon>
												</Avatar>
											}
											title={
												<Typography variant="subtitle2">
													{t('app:FuelPump.fuelAvailable')}
												</Typography>
											}
											subheader={`${collectableFuel.toLocaleString()} ${t(
												'app:Generic.of'
											)} ${fuelPumpCapacity.toLocaleString()}`}
										/>
										<Divider />
										<CardHeader
											sx={{ p: 1 }}
											avatar={
												<Avatar
													variant="rounded"
													sx={{ backgroundColor: blueGrey[500] }}
												>
													<SvgIcon fontSize="medium">
														<FontAwesomeIcon icon={faClock} color="#fff" />
													</SvgIcon>
												</Avatar>
											}
											title={
												<Typography variant="subtitle2">
													{t('app:Generic.timeTillFull')}
												</Typography>
											}
											subheader={`${timeTillCapped}`}
										/>
										<LoadingButton
											fullWidth
											variant="contained"
											sx={{ borderRadius: 0 }}
											loading={isCollecting}
											onClick={handleCollectClick}
										>
											{t('app:Generic.collect')}
										</LoadingButton>
									</Card>
								</Stack>
							</Stack>
						</Box>
						<Box
							flexGrow={1}
							sx={{
								background: 'url("/assets/images/fuel-pump.jpg")',
								backgroundSize: 'cover',
								backgroundPosition: 'center'
							}}
						></Box>
					</Stack>
				</Box>
			</Dialog>
		</>
	)
}

export default FuelPumpDialog
