import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	Stack,
	SvgIcon,
	TextField,
	Tooltip,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch } from '../../redux'
import { login, register } from '../../redux/auth/thunks'
import { sendSnackbarNotification } from '../../util/notifications/notifications'

const QuickPlay = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [email] = useState(`${uuidv4().split('-')[4]}@mercenariesonline.com`)
	const [password] = useState(uuidv4())
	const [copiedConfirmation, setCopiedConfirmation] = useState(false)
	const [copiedConfirmationError, setCopiedConfirmationError] = useState(false)
	const [agreeToTermsOfUse, setAgreeToTermsOfUse] = useState(false)
	const [agreeToTermsOfUseError, setAgreeToTermsOfUseError] = useState(false)
	const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false)
	const [agreeToPrivacyPolicyError, setAgreeToPrivacyPolicyError] =
		useState(false)
	const [isRegistering, setIsRegistering] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handleCopiedConfirmationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setCopiedConfirmation(event.target.checked)
		if (copiedConfirmationError && event.target.checked) {
			setCopiedConfirmationError(false)
		}
	}

	const handleAgreeToTermsOfUseChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAgreeToTermsOfUse(event.target.checked)
		if (agreeToTermsOfUseError && event.target.checked) {
			setAgreeToTermsOfUseError(false)
		}
	}

	const handleAgreeToPrivacyPolicyChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAgreeToPrivacyPolicy(event.target.checked)
		if (agreeToPrivacyPolicyError && event.target.checked) {
			setAgreeToPrivacyPolicyError(false)
		}
	}

	const handleRegisterSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			let hasError = false
			if (!copiedConfirmation) {
				setCopiedConfirmationError(true)
				hasError = true
			}
			if (!agreeToTermsOfUse) {
				setAgreeToTermsOfUseError(true)
				hasError = true
			}
			if (!agreeToPrivacyPolicy) {
				setAgreeToPrivacyPolicyError(true)
				hasError = true
			}

			if (!hasError) {
				setErrorText('')
				setIsRegistering(true)
				const wrappedRegisterResult = await dispatch(
					register({ email, password })
				)
				unwrapResult(wrappedRegisterResult) // unwrap to catch errors

				// Registration successful, login automatically
				const wrappedLoginResult = await dispatch(
					login({ email, password, persist: false })
				)
				unwrapResult(wrappedLoginResult) // unwrap to catch errors
				// Navigate to root
				navigate('/')
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsRegistering(false)
		}
	}

	const handleCopyEmailClick = () => {
		navigator.clipboard.writeText(email)
		sendSnackbarNotification(t('app:Auth.emailIsCopiedToClipboard'))
	}

	const handleCopyPasswordClick = () => {
		navigator.clipboard.writeText(password)
		sendSnackbarNotification(t('app:Auth.passwordIsCopiedToClipboard'))
	}

	const handleBackToLoginClick = () => {
		navigate('/')
	}

	let error
	if (errorText) {
		error = <Alert severity="error">{errorText}</Alert>
	}

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<form onSubmit={handleRegisterSubmit}>
				<Grid container spacing={2} padding={2}>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<img
								alt="Mercenaries Online *i18n*"
								src="/assets/images/auth-logo.png"
								width="100%"
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<Paper elevation={1}>
								<Stack padding={2} spacing={2}>
									<Typography variant="h5">
										{t('app:Auth.quickPlay')}
									</Typography>
									{error}
									<TextField
										variant="filled"
										label={t('app:Auth.email')}
										value={email}
										disabled={true}
										helperText={t('app:Auth.quickPlayEmailDescription')}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Tooltip title={t('app:Generic.copy')}>
														<IconButton onClick={handleCopyEmailClick}>
															<SvgIcon>
																<FontAwesomeIcon icon={faCopy} color="#fff" />
															</SvgIcon>
														</IconButton>
													</Tooltip>
												</InputAdornment>
											)
										}}
									/>
									<TextField
										variant="filled"
										label={t('app:Auth.password')}
										value={password}
										disabled={true}
										helperText={t('app:Auth.quickPlayPasswordDescription')}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Tooltip title={t('app:Generic.copy')}>
														<IconButton onClick={handleCopyPasswordClick}>
															<SvgIcon>
																<FontAwesomeIcon icon={faCopy} color="#fff" />
															</SvgIcon>
														</IconButton>
													</Tooltip>
												</InputAdornment>
											)
										}}
									/>
									<Stack direction="column">
										<Stack direction="row" alignItems="center">
											<Checkbox
												value={copiedConfirmation}
												onChange={handleCopiedConfirmationChange}
											/>
											<Typography>
												{t('app:Auth.quickPlayCopiedConfirmation')}
											</Typography>
										</Stack>
										<Box
											display={copiedConfirmationError ? 'block' : 'none'}
											pl={2}
										>
											<FormHelperText error={true}>
												{t('app:Auth.quickPlayCopiedConfirmationError')}
											</FormHelperText>
										</Box>
									</Stack>
									<Stack direction="column">
										<Stack direction="row" alignItems="center">
											<Checkbox
												value={agreeToTermsOfUse}
												onChange={handleAgreeToTermsOfUseChange}
											/>
											<Typography>
												{t('app:Auth.agreeTo')}
												<Link
													to="https://www.mercenariesonline.com/terms-of-use"
													target="_blank"
													style={{ color: '#fff' }}
												>
													{t('app:Auth.termsOfUse')}
												</Link>
											</Typography>
										</Stack>
										<Box
											display={agreeToTermsOfUseError ? 'block' : 'none'}
											pl={2}
										>
											<FormHelperText error={true}>
												{t('app:Auth.agreeToTermsOfUseError')}
											</FormHelperText>
										</Box>
									</Stack>
									<Stack direction="column">
										<Stack direction="row" alignItems="center">
											<Checkbox
												value={agreeToPrivacyPolicy}
												onChange={handleAgreeToPrivacyPolicyChange}
											/>
											<Typography>
												{t('app:Auth.agreeTo')}
												<Link
													to="https://www.mercenariesonline.com/privacy-policy"
													target="_blank"
													style={{ color: '#fff' }}
												>
													{t('app:Auth.privacyPolicy')}
												</Link>
											</Typography>
										</Stack>
										<Box
											display={agreeToPrivacyPolicyError ? 'block' : 'none'}
											pl={2}
										>
											<FormHelperText error={true}>
												{t('app:Auth.agreeToPrivacyPolicyError')}
											</FormHelperText>
										</Box>
									</Stack>
									<LoadingButton
										variant="contained"
										size="large"
										type="submit"
										loading={isRegistering}
									>
										{t('app:Auth.register')}
									</LoadingButton>
									<Button
										fullWidth
										variant="contained"
										size="large"
										disabled={isRegistering}
										onClick={handleBackToLoginClick}
									>
										{t('app:Auth.backToLogin')}
									</Button>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default QuickPlay
