import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormHelperText,
	Grid,
	Paper,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux'
import { login, register } from '../../redux/auth/thunks'
import {
	isValidConfirmPassword,
	isValidEmail,
	isValidPassword
} from '../../util/validation/inputs'

const Register = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState(false)
	const [confirmPassword, setConfirmPassword] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState(false)
	const [agreeToTermsOfUse, setAgreeToTermsOfUse] = useState(false)
	const [agreeToTermsOfUseError, setAgreeToTermsOfUseError] = useState(false)
	const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false)
	const [agreeToPrivacyPolicyError, setAgreeToPrivacyPolicyError] =
		useState(false)
	const [isRegistering, setIsRegistering] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (emailError && isValidEmail(event.target.value)) {
			setEmailError(false)
		}
		setEmail(event.target.value)
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (passwordError && isValidPassword(event.target.value)) {
			setPasswordError(false)
		}
		setPassword(event.target.value)
	}

	const handleConfirmPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (
			confirmPasswordError &&
			isValidConfirmPassword(event.target.value, password)
		) {
			setConfirmPasswordError(false)
		}
		setConfirmPassword(event.target.value)
	}

	const handleAgreeToTermsOfUseChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAgreeToTermsOfUse(event.target.checked)
		if (agreeToTermsOfUseError && event.target.checked) {
			setAgreeToTermsOfUseError(false)
		}
	}

	const handleAgreeToPrivacyPolicyChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAgreeToPrivacyPolicy(event.target.checked)
		if (agreeToPrivacyPolicyError && event.target.checked) {
			setAgreeToPrivacyPolicyError(false)
		}
	}

	const handleRegisterSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			let hasError = false
			if (!isValidEmail(email)) {
				setEmailError(true)
				hasError = true
			}
			if (!isValidPassword(password)) {
				setPasswordError(true)
				hasError = true
			}
			if (!isValidConfirmPassword(confirmPassword, password)) {
				setConfirmPasswordError(true)
				hasError = true
			}
			if (!agreeToTermsOfUse) {
				setAgreeToTermsOfUseError(true)
				hasError = true
			}
			if (!agreeToPrivacyPolicy) {
				setAgreeToPrivacyPolicyError(true)
				hasError = true
			}

			if (!hasError) {
				setErrorText('')
				setIsRegistering(true)
				const wrappedRegisterResult = await dispatch(
					register({ email, password })
				)
				unwrapResult(wrappedRegisterResult) // unwrap to catch errors

				// Registration successful, login automatically
				const wrappedLoginResult = await dispatch(
					login({ email, password, persist: false })
				)
				unwrapResult(wrappedLoginResult) // unwrap to catch errors
				// Navigate to root
				navigate('/')
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsRegistering(false)
		}
	}

	const handleBackToLoginClick = () => {
		navigate('/')
	}

	const handleQuickPlayClick = () => {
		navigate('/quick-play')
	}

	let error
	if (errorText) {
		error = <Alert severity="error">{errorText}</Alert>
	}

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<form onSubmit={handleRegisterSubmit}>
				<Grid container spacing={2} padding={2}>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<img
								alt="Mercenaries Online *i18n*"
								src="/assets/images/auth-logo.png"
								width="100%"
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<Paper elevation={1} sx={{ mb: 1 }}>
								<Stack padding={2}>
									<Button
										fullWidth
										variant="contained"
										size="large"
										disabled={isRegistering}
										onClick={handleQuickPlayClick}
									>
										{t('app:Auth.quickPlay')}
									</Button>
									<Typography variant="caption" textAlign="center">
										{t('app:Auth.quickPlayDescription')}
									</Typography>
								</Stack>
							</Paper>
							<Paper elevation={1}>
								<Stack padding={2} spacing={2}>
									<Typography variant="h5">{t('app:Auth.register')}</Typography>
									{error}
									<TextField
										required
										autoFocus
										variant="filled"
										label={t('app:Auth.email')}
										value={email}
										onChange={handleEmailChange}
										disabled={isRegistering}
										helperText={emailError ? t('app:Auth.emailError') : ''}
										error={emailError}
									/>
									<TextField
										required
										variant="filled"
										label={t('app:Auth.password')}
										type="password"
										value={password}
										onChange={handlePasswordChange}
										disabled={isRegistering}
										helperText={
											passwordError ? t('app:Auth.passwordError') : ''
										}
										error={passwordError}
									/>
									<TextField
										required
										variant="filled"
										label={t('app:Auth.confirmPassword')}
										type="password"
										value={confirmPassword}
										onChange={handleConfirmPasswordChange}
										disabled={isRegistering}
										helperText={
											confirmPasswordError
												? t('app:Auth.confirmPasswordError')
												: ''
										}
										error={confirmPasswordError}
									/>
									<Stack direction="column">
										<Stack direction="row" alignItems="center">
											<Checkbox
												value={agreeToTermsOfUse}
												onChange={handleAgreeToTermsOfUseChange}
											/>
											<Typography>
												{t('app:Auth.agreeTo')}
												<Link
													to="https://www.mercenariesonline.com/terms-of-use"
													target="_blank"
													style={{ color: '#fff' }}
												>
													{t('app:Auth.termsOfUse')}
												</Link>
											</Typography>
										</Stack>
										<Box
											display={agreeToTermsOfUseError ? 'block' : 'none'}
											pl={2}
										>
											<FormHelperText error={true}>
												{t('app:Auth.agreeToTermsOfUseError')}
											</FormHelperText>
										</Box>
									</Stack>
									<Stack direction="column">
										<Stack direction="row" alignItems="center">
											<Checkbox
												value={agreeToPrivacyPolicy}
												onChange={handleAgreeToPrivacyPolicyChange}
											/>
											<Typography>
												{t('app:Auth.agreeTo')}
												<Link
													to="https://www.mercenariesonline.com/privacy-policy"
													target="_blank"
													style={{ color: '#fff' }}
												>
													{t('app:Auth.privacyPolicy')}
												</Link>
											</Typography>
										</Stack>
										<Box
											display={agreeToPrivacyPolicyError ? 'block' : 'none'}
											pl={2}
										>
											<FormHelperText error={true}>
												{t('app:Auth.agreeToPrivacyPolicyError')}
											</FormHelperText>
										</Box>
									</Stack>
									<LoadingButton
										variant="contained"
										size="large"
										type="submit"
										loading={isRegistering}
									>
										{t('app:Auth.register')}
									</LoadingButton>
									<Button
										fullWidth
										variant="contained"
										size="large"
										disabled={isRegistering}
										onClick={handleBackToLoginClick}
									>
										{t('app:Auth.backToLogin')}
									</Button>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default Register
