import {
	ProfilesCreateProfileRequestBody,
	ProfilesCreateProfileResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const createProfile = createAsyncThunk(
	'profiles/createProfile',
	async (
		payload: ProfilesCreateProfileRequestBody
	): Promise<ProfilesCreateProfileResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/createProfile',
			payload
		)
		return response
	}
)
