import { getProfileRankFromProfileExperience } from '@allardvanderouw/mercenariesonline-commons'
import { LeaderboardPosition } from '@allardvanderouw/mercenariesonline-types'
import { Avatar, CardHeader, Divider, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { getProfileRankTitleText } from '../../../../util/profile/level'

interface LeaderboardPositionFragmentProps {
	divider?: boolean
	position: LeaderboardPosition
	type: 'experience' | 'fame'
	rank: number
}

const LeaderboardPositionFragment: React.FC<
	LeaderboardPositionFragmentProps
> = ({ divider, position, type, rank }) => {
	let dividerComponent
	if (divider) {
		dividerComponent = <Divider />
	}

	const heroRank = getProfileRankFromProfileExperience(position.experience)
	const heroRankTitle = getProfileRankTitleText(heroRank.title)

	let description
	if (type === 'experience') {
		description = `${t('app:Generic.level')} ${
			heroRank.level
		} (${position.experience.toLocaleString()} ${t('app:Generic.xp')})`
	}
	if (type === 'fame') {
		description = `${position.fame.toLocaleString()} ${t('app:Generic.fame')}`
	}

	return (
		<>
			<CardHeader
				sx={{ p: 1 }}
				avatar={
					<Avatar
						sx={{
							backgroundColor: blueGrey[500],
							color: '#fff',
							width: '64px',
							fontWeight: 'bold'
						}}
						variant="rounded"
					>
						{rank}
					</Avatar>
				}
				title={
					<Typography variant="subtitle2">
						{heroRankTitle} {position.heroName}
					</Typography>
				}
				subheader={description}
			/>
			{dividerComponent}
		</>
	)
}

export default LeaderboardPositionFragment
