import { LoadingButton } from '@mui/lab'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useAppDispatch } from '../../../../redux'
import { changeEmail } from '../../../../redux/user/thunks'
import {
	ActiveDialog,
	DialogKey,
	SettingsDialogSubMenu
} from '../../../../types'
import { sendSnackbarNotification } from '../../../../util/notifications/notifications'
import { isValidEmail } from '../../../../util/validation/inputs'

interface SettingsSubMenuChangeEmailProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
	setSubMenu: (subMenu: SettingsDialogSubMenu) => void
}

const SettingsSubMenuChangeEmail: React.FC<SettingsSubMenuChangeEmailProps> = ({
	setActiveDialog,
	setSubMenu
}) => {
	const dispatch = useAppDispatch()
	const [errorText, setErrorText] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [password, setPassword] = useState('')
	const [newEmail, setNewEmail] = useState('')
	const [newEmailError, setNewEmailError] = useState(false)

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}

	const handleNewEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (newEmailError && isValidEmail(event.target.value)) {
			setNewEmailError(false)
		}
		setNewEmail(event.target.value)
	}

	const onChangeEmailClick = async () => {
		try {
			if (!isValidEmail(newEmail)) {
				setNewEmailError(true)
			} else {
				setIsLoading(true)
				const wrappedResult = await dispatch(
					changeEmail({ password, newEmail })
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
				sendSnackbarNotification(
					t('app:Settings.ChangeEmail.emailChanged'),
					'success'
				)
				setActiveDialog({ key: DialogKey.None })
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsLoading(false)
		}
	}

	const onBackToSettingsClick = () => {
		setSubMenu(SettingsDialogSubMenu.None)
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<>
			{errorDialog}
			<TextField
				autoFocus
				required
				label={t('app:Settings.ChangeEmail.password')}
				variant="filled"
				type="password"
				value={password}
				onChange={handlePasswordChange}
				disabled={isLoading}
			/>
			<TextField
				required
				label={t('app:Settings.ChangeEmail.newEmail')}
				variant="filled"
				value={newEmail}
				onChange={handleNewEmailChange}
				disabled={isLoading}
				helperText={newEmailError ? t('app:Auth.emailError') : ''}
				error={newEmailError}
			/>
			<LoadingButton
				fullWidth
				loading={isLoading}
				variant="contained"
				onClick={onChangeEmailClick}
			>
				{t('app:Settings.changeEmail')}
			</LoadingButton>
			<Button fullWidth variant="contained" onClick={onBackToSettingsClick}>
				{t('app:Generic.back')}
			</Button>
		</>
	)
}

export default SettingsSubMenuChangeEmail
