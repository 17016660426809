import {
	UsersSettingsChangeEmailRequestBody,
	UsersSettingsChangeEmailResponseBody,
	UsersSettingsChangePasswordRequestBody,
	UsersSettingsChangePasswordResponseBody,
	UsersSettingsDeleteAccountRequestBody,
	UsersSettingsDeleteAccountResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const changePassword = createAsyncThunk(
	'users/changePassword',
	async (
		payload: UsersSettingsChangePasswordRequestBody
	): Promise<UsersSettingsChangePasswordResponseBody> => {
		const response = await callFunction(
			'/functions/users/settings/changePassword',
			payload
		)
		return response
	}
)

export const changeEmail = createAsyncThunk(
	'users/changeEmail',
	async (
		payload: UsersSettingsChangeEmailRequestBody
	): Promise<UsersSettingsChangeEmailResponseBody> => {
		const response = await callFunction(
			'/functions/users/settings/changeEmail',
			payload
		)
		return response
	}
)

export const deleteAccount = createAsyncThunk(
	'users/deleteAccount',
	async (
		payload: UsersSettingsDeleteAccountRequestBody
	): Promise<UsersSettingsDeleteAccountResponseBody> => {
		const response = await callFunction(
			'/functions/users/settings/deleteAccount',
			payload
		)
		return response
	}
)
