export enum DialogKey {
	Armory = 'ARMORY',
	BitcoinFarm = 'BITCOIN_FARM',
	BountyHub = 'BOUNTY_HUB',
	FinishMission = 'FINISH_MISSION',
	FuelPump = 'FUEL_PUMP',
	Leaderboard = 'LEADERBOARD',
	Membership = 'MEMBERSHIP',
	PlayMission = 'PLAY_MISSION',
	Settings = 'SETTINGS',
	StartMission = 'START_MISSION',
	WeaponPartsFactory = 'WEAPON_PARTS_FACTORY',
	None = 'NONE'
}

export interface ActiveDialog {
	key: DialogKey
	data?: any
}

export enum MembershipDialogStep {
	None = 'NONE',
	Success = 'SUCCESS' // matches return value from server
}

export enum SettingsDialogSubMenu {
	None = 'NONE',
	ChangeEmail = 'CHANGE_EMAIL',
	ChangePassword = 'CHANGE_PASSWORD',
	DeleteAccount = 'DELETE_ACCOUNT'
}
