import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Box,
	Grid,
	Paper,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useAppDispatch } from '../../redux'
import { createProfile } from '../../redux/profile/thunks'
import { isValidProfileName } from '../../util/validation/inputs'

const CreateProfile = () => {
	const dispatch = useAppDispatch()
	const [heroName, setName] = useState('')
	const [heroNameError, setNameError] = useState(false)
	const [isCreating, setIsCreating] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (heroNameError && isValidProfileName(event.target.value)) {
			setNameError(false)
		}
		setName(event.target.value)
	}

	const handleCreateProfileSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			if (!isValidProfileName(heroName)) {
				setNameError(true)
			} else {
				setErrorText('')
				setIsCreating(true)
				const wrappedResult = await dispatch(createProfile({ heroName }))
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsCreating(false)
		}
	}

	let error
	if (errorText) {
		error = <Alert severity="error">{errorText}</Alert>
	}

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<form onSubmit={handleCreateProfileSubmit}>
				<Grid container spacing={2} padding={2}>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<img
								alt="Mercenaries Online *i18n*"
								src="/assets/images/auth-logo.png"
								width="100%"
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<Paper elevation={1}>
								<Stack padding={2} spacing={2}>
									<Typography variant="h5">
										{t('app:CreateProfile.title')}
									</Typography>
									{error}
									<TextField
										required
										autoFocus
										variant="filled"
										label={t('app:CreateProfile.heroName')}
										value={heroName}
										onChange={handleNameChange}
										helperText={
											heroNameError
												? t('app:CreateProfile.heroNameError')
												: t('app:CreateProfile.heroNameVisibility')
										}
										error={heroNameError}
									/>
									<LoadingButton
										variant="contained"
										size="large"
										type="submit"
										loading={isCreating}
									>
										{t('app:CreateProfile.start')}
									</LoadingButton>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default CreateProfile
