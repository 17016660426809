import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Card,
	CardHeader,
	IconButton,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { ActiveDialog, DialogKey } from '../../../types'

interface WeaponPartsCardProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const WeaponPartsCard: React.FC<WeaponPartsCardProps> = ({
	setActiveDialog
}) => {
	const weaponParts = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.weaponParts
	)

	const handlePlusClick = () => {
		setActiveDialog({ key: DialogKey.WeaponPartsFactory })
	}

	return (
		<Card sx={{ backgroundColor: blueGrey[700] }}>
			<CardHeader
				sx={{ p: 1 }}
				avatar={
					<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
						<SvgIcon fontSize="medium">
							<FontAwesomeIcon icon={faCog} color="#fff" />
						</SvgIcon>
					</Avatar>
				}
				title={
					<Typography variant="subtitle2">
						{t('app:Profile.weaponParts')}
					</Typography>
				}
				subheader={weaponParts.toLocaleString()}
				action={
					<IconButton onClick={handlePlusClick}>
						<SvgIcon>
							<FontAwesomeIcon icon={faPlus} color="#fff" />
						</SvgIcon>
					</IconButton>
				}
			/>
		</Card>
	)
}

export default WeaponPartsCard
