import {
	TransactionStatus,
	UsersMembershipGetTransactionStatusResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../../redux'
import { getMembershipTransactionStatus } from '../../../../redux/user/thunks/membership'
import { MembershipDialogStep } from '../../../../types'

interface MembershipStepSuccessProps {
	checkoutID: string | null
	setErrorText: (text: string) => void
	setStep: (step: MembershipDialogStep) => void
}

const MembershipStepSuccess: React.FC<MembershipStepSuccessProps> = ({
	checkoutID,
	setErrorText,
	setStep
}) => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		const refresh = async () => {
			if (checkoutID) {
				try {
					const wrappedResult = await dispatch(
						getMembershipTransactionStatus({ checkoutID })
					)
					unwrapResult(wrappedResult) // unwrap to catch errors
					const result =
						wrappedResult.payload as UsersMembershipGetTransactionStatusResponseBody
					if (result.meta.status === TransactionStatus.Completed) {
						setStep(MembershipDialogStep.None)
					}
					if (result.meta.status === TransactionStatus.Failed) {
						setStep(MembershipDialogStep.None)
						setErrorText(t('app:Membership.transactionFailed'))
					}
				} catch (error: any) {
					setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
				}
			}
		}

		const intervalId = setInterval(refresh, 5000)
		return () => clearInterval(intervalId)
	}, [checkoutID, dispatch, setErrorText, setStep])

	return (
		<>
			<Box flexGrow={1}>
				<Stack
					width="100%"
					height="100%"
					sx={{
						display: 'flex',
						backgroundColor: blueGrey[800],
						borderRight: `4px solid ${blueGrey[500]}`
					}}
				>
					<Box padding={1}>
						<Typography variant="h6" align="center">
							{t('app:Membership.Benefits.bigThanks')}
						</Typography>
						<Typography align="center" paragraph>
							{t('app:Membership.processingTransaction')}
						</Typography>
						<Stack
							direction="column"
							padding={2}
							spacing={2}
							alignItems="center"
						>
							<CircularProgress />
							<Typography variant="h6">
								{t('app:Generic.processing')}
							</Typography>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</>
	)
}

export default MembershipStepSuccess
