import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Paper,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux'
import { login } from '../../redux/auth/thunks'
import { isValidEmail, isValidPassword } from '../../util/validation/inputs'

const Login = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState(false)
	const [rememberMe, setRememberMe] = useState(false)
	const [isLoggingIn, setIsLoggingIn] = useState(false)
	const [errorText, setErrorText] = useState('')

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (emailError && isValidEmail(event.target.value)) {
			setEmailError(false)
		}
		setEmail(event.target.value)
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (passwordError && isValidPassword(event.target.value)) {
			setPasswordError(false)
		}
		setPassword(event.target.value)
	}

	const handleRememberMeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRememberMe(event.target.checked)
	}

	const handleLoginSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		try {
			if (!isValidEmail(email)) {
				setEmailError(true)
			} else if (!isValidPassword(password)) {
				setPasswordError(true)
			} else {
				setErrorText('')
				setIsLoggingIn(true)
				const wrappedResult = await dispatch(
					login({ email, password, persist: rememberMe })
				)
				unwrapResult(wrappedResult) // unwrap to catch errors
			}
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
			setIsLoggingIn(false)
		}
	}

	const handleRegisterClick = () => {
		navigate('/register')
	}

	const handleForgotPasswordClick = () => {
		navigate('/forgot-password')
	}

	const handleQuickPlayClick = () => {
		navigate('/quick-play')
	}

	let error
	if (errorText) {
		error = <Alert severity="error">{errorText}</Alert>
	}

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="auto"
		>
			<form onSubmit={handleLoginSubmit}>
				<Grid container spacing={2} padding={2}>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<img
								alt="Mercenaries Online *i18n*"
								src="/assets/images/auth-logo.png"
								width="100%"
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={11} sm={8} md={7} lg={6} xl={5}>
							<Paper elevation={1} sx={{ mb: 1 }}>
								<Stack padding={2}>
									<Button
										fullWidth
										variant="contained"
										size="large"
										disabled={isLoggingIn}
										onClick={handleQuickPlayClick}
									>
										{t('app:Auth.quickPlay')}
									</Button>
									<Typography variant="caption" textAlign="center">
										{t('app:Auth.quickPlayDescription')}
									</Typography>
								</Stack>
							</Paper>
							<Paper elevation={1}>
								<Stack padding={2} spacing={2}>
									<Typography variant="h5">{t('app:Auth.login')}</Typography>
									{error}
									<TextField
										required
										autoFocus
										variant="filled"
										label={t('app:Auth.email')}
										value={email}
										onChange={handleEmailChange}
										disabled={isLoggingIn}
										helperText={emailError ? t('app:Auth.emailError') : ''}
										error={emailError}
									/>
									<TextField
										required
										variant="filled"
										label={t('app:Auth.password')}
										type="password"
										value={password}
										onChange={handlePasswordChange}
										disabled={isLoggingIn}
										helperText={
											passwordError ? t('app:Auth.passwordError') : ''
										}
										error={passwordError}
									/>
									<FormControlLabel
										control={
											<Checkbox
												value={rememberMe}
												onChange={handleRememberMeChange}
											/>
										}
										label={t('app:Auth.rememberMe')}
									/>
									<LoadingButton
										variant="contained"
										size="large"
										type="submit"
										loading={isLoggingIn}
									>
										{t('app:Auth.login')}
									</LoadingButton>
								</Stack>
								<Grid
									container
									spacing={2}
									paddingLeft={2}
									paddingRight={2}
									paddingBottom={2}
								>
									<Grid item xs={12} sm={6}>
										<Button
											fullWidth
											variant="contained"
											size="large"
											disabled={isLoggingIn}
											onClick={handleRegisterClick}
										>
											{t('app:Auth.register')}
										</Button>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Button
											fullWidth
											variant="contained"
											size="large"
											disabled={isLoggingIn}
											onClick={handleForgotPasswordClick}
										>
											{t('app:Auth.forgotPassword')}
										</Button>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Box>
	)
}

export default Login
