import { Box, CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../redux'
import { autoLogin } from '../../redux/auth/thunks'
import { isSnackbarNotificationEvent } from '../../types'
import { AppEventType } from '../../util/notifications/notifications'
import { makeStyles } from '../../util/styles'
import ForgotPassword from '../Auth/ForgotPassword'
import Login from '../Auth/Login'
import QuickPlay from '../Auth/QuickPlay'
import Register from '../Auth/Register'
import ResetPassword from '../Auth/ResetPassword'
import CreateProfile from '../CreateProfile/CreateProfile'
import Game from '../Game/Game'
import Loading from '../Shared/Loading'
import getTheme from './Theme'

const sx = makeStyles({
	'@global': {
		'::-webkit-scrollbar': {
			background: (theme) =>
				theme.palette.mode === 'dark' ? '#282828' : '#eee',
			height: 7,
			width: 7
		},
		'::-webkit-scrollbar-thumb': {
			background: (theme) =>
				theme.palette.mode === 'dark' ? '#636363' : '#ddd',
			borderRadius: 2
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: (theme) => (theme.palette.mode === 'dark' ? '#999' : '#999')
		}
	},
	root: {
		display: 'flex',
		overflow: 'hidden',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		background: 'url("/assets/images/auth-background.jpg")',
		backgroundSize: 'cover',
		backgroundPosition: 'center center'
	},
	content: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden'
	}
})

const App = () => {
	const dispatch = useAppDispatch()
	const [isAutoLoggingIn, setIsAutoLoggingIn] = useState(true)
	const user = useSelector((state: RootState) => state.user)
	const profile = useSelector((state: RootState) => state.profile.entity)
	const hasProfile = Boolean(profile && profile.ID)

	const { enqueueSnackbar } = useSnackbar()

	//Handle snackbar notification events
	useEffect(() => {
		const handleSnackbarNotification = (event: Event) => {
			if (isSnackbarNotificationEvent(event)) {
				const { message, options } = event.detail
				enqueueSnackbar(message, options)
			}
		}
		window.addEventListener(
			AppEventType.SnackbarNotification,
			handleSnackbarNotification
		)
		return () => {
			window.removeEventListener(
				AppEventType.SnackbarNotification,
				handleSnackbarNotification
			)
		}
	})

	// Immediately trigger the autologin
	useEffect(() => {
		const startAutoLogin = async () => {
			try {
				await dispatch(autoLogin())
			} finally {
				setIsAutoLoggingIn(false)
			}
		}
		startAutoLogin()
	}, [dispatch])

	let content
	if (isAutoLoggingIn) {
		content = (
			<Box sx={sx.root}>
				<Loading message={t('app:Auth.isAutoLoggingIn')} />
			</Box>
		)
	} else if (!user.ID) {
		// User is not logged in
		content = (
			<Box sx={sx.root}>
				<Routes>
					<Route path="/register" element={<Register />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/quick-play" element={<QuickPlay />} />
					<Route path="*" element={<Login />} />
				</Routes>
			</Box>
		)
	} else if (!hasProfile) {
		// User does not have a profile; create profile
		content = (
			<Box sx={sx.root}>
				<CreateProfile />
			</Box>
		)
	} else {
		// User is logged in and has a profile; play game
		content = (
			<Box sx={sx.root}>
				<Game />
			</Box>
		)
	}

	const theme = getTheme()
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStyles
				styles={{
					'::-webkit-scrollbar': {
						background: '#eee',
						width: 8,
						height: 8
					},
					'::-webkit-scrollbar-thumb': {
						background: '#ddd',
						borderRadius: 2
					},
					'::-webkit-scrollbar-thumb:hover': {
						background: '#999'
					}
				}}
			/>
			{content}
		</ThemeProvider>
	)
}

export default App
