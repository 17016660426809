import { faEnvelope, faKey, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Button,
	Card,
	CardHeader,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { SettingsDialogSubMenu } from '../../../../types'

interface SettingsMainMenuProps {
	setSubMenu: (subMenu: SettingsDialogSubMenu) => void
}

const SettingsMainMenu: React.FC<SettingsMainMenuProps> = ({ setSubMenu }) => {
	const email = useSelector((state: RootState) => state.user.email)

	const onChangeEmailClick = () => {
		setSubMenu(SettingsDialogSubMenu.ChangeEmail)
	}

	const onChangePasswordClick = () => {
		setSubMenu(SettingsDialogSubMenu.ChangePassword)
	}

	const onDeleteAccountClick = () => {
		setSubMenu(SettingsDialogSubMenu.DeleteAccount)
	}

	return (
		<>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faEnvelope} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Settings.email')}
						</Typography>
					}
					subheader={email}
				/>
				<Button
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					onClick={onChangeEmailClick}
				>
					{t('app:Settings.changeEmail')}
				</Button>
			</Card>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faKey} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Settings.password')}
						</Typography>
					}
				/>
				<Button
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					onClick={onChangePasswordClick}
				>
					{t('app:Settings.changePassword')}
				</Button>
			</Card>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faTrash} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Settings.deleteAccount')}
						</Typography>
					}
				/>
				<Button
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					onClick={onDeleteAccountClick}
				>
					{t('app:Settings.deleteAccount')}
				</Button>
			</Card>
		</>
	)
}

export default SettingsMainMenu
