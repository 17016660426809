import { Typography, TypographyVariant } from '@mui/material'
import { grey } from '@mui/material/colors'
import { t } from 'i18next'
import { LatLngExpression } from 'leaflet'
import { Marker } from 'react-leaflet-marker'

interface ZoneMarkerProps {
	label: string
	variant: TypographyVariant
	position: LatLngExpression
	requiredLevel?: number
	color?: string
}

const ZoneMarker: React.FC<ZoneMarkerProps> = ({
	label,
	variant,
	position,
	requiredLevel,
	color
}) => {
	let requiredLevelText
	if (requiredLevel) {
		requiredLevelText = (
			<Typography
				variant="body2"
				sx={{
					color: grey['A700'],
					fontWeight: 'bold',
					textAlign: 'center'
				}}
			>
				{t('app:Generic.level')} {requiredLevel}
			</Typography>
		)
	}

	return (
		<div style={{ position: 'absolute' }}>
			<Marker position={position} size={[300, 50]}>
				<Typography
					variant={variant}
					sx={{
						color: color ? color : grey['A700'],
						fontWeight: 'bold',
						textAlign: 'center'
					}}
				>
					{label}
				</Typography>
				{requiredLevelText}
			</Marker>
		</div>
	)
}

export default ZoneMarker
