import { faDollarSign, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Card,
	CardHeader,
	IconButton,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { ActiveDialog, DialogKey } from '../../../types'

interface CashCardProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const CashCard: React.FC<CashCardProps> = ({ setActiveDialog }) => {
	const cash = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.cash
	)

	const handlePlusClick = () => {
		setActiveDialog({ key: DialogKey.BitcoinFarm })
	}

	return (
		<Card sx={{ backgroundColor: blueGrey[700] }}>
			<CardHeader
				sx={{ p: 1 }}
				avatar={
					<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
						<SvgIcon fontSize="medium">
							<FontAwesomeIcon icon={faDollarSign} color="#fff" />
						</SvgIcon>
					</Avatar>
				}
				title={
					<Typography variant="subtitle2">{t('app:Profile.cash')}</Typography>
				}
				subheader={cash.toLocaleString()}
				action={
					<IconButton onClick={handlePlusClick}>
						<SvgIcon>
							<FontAwesomeIcon icon={faPlus} color="#fff" />
						</SvgIcon>
					</IconButton>
				}
			/>
		</Card>
	)
}

export default CashCard
