import { ItemSlot } from '@allardvanderouw/mercenariesonline-types'
import { Card, CardHeader, Divider, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import EquipmentItemFragment from './EquipmentItemFragment'

const Equipment: React.FC = () => {
	const equipment = useSelector(
		(state: RootState) => state.profile.entity.equipment
	)

	return (
		<>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					title={
						<Typography variant="subtitle2">
							{t('app:Armory.weapons')}
						</Typography>
					}
				/>
				<Divider />
				<EquipmentItemFragment
					itemSlot={ItemSlot.PrimaryWeapon}
					equipmentItem={equipment.primaryWeapon}
				/>
				<Divider />
				<EquipmentItemFragment
					itemSlot={ItemSlot.SecondaryWeapon}
					equipmentItem={equipment.secondaryWeapon}
				/>
				<Divider />
				<EquipmentItemFragment
					itemSlot={ItemSlot.SpecialWeapon}
					equipmentItem={equipment.specialWeapon}
				/>
			</Card>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					title={
						<Typography variant="subtitle2">{t('app:Armory.gear')}</Typography>
					}
				/>
				<Divider />
				<EquipmentItemFragment
					itemSlot={ItemSlot.Head}
					equipmentItem={equipment.head}
					divider
				/>
				<EquipmentItemFragment
					itemSlot={ItemSlot.Chest}
					equipmentItem={equipment.chest}
					divider
				/>
				<EquipmentItemFragment
					itemSlot={ItemSlot.Legs}
					equipmentItem={equipment.legs}
					divider
				/>
				<EquipmentItemFragment
					itemSlot={ItemSlot.Feet}
					equipmentItem={equipment.feet}
					divider
				/>
				<EquipmentItemFragment
					itemSlot={ItemSlot.Hands}
					equipmentItem={equipment.hands}
				/>
			</Card>
		</>
	)
}

export default Equipment
