import { User } from '@allardvanderouw/mercenariesonline-types'
import { createSlice } from '@reduxjs/toolkit'
import * as authThunks from '../auth/thunks'
import * as thunks from './thunks'

const initialState = {} as User

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//
		// Auth thunks
		//
		builder.addCase(authThunks.login.fulfilled, (state, action) => {
			return action.payload.user
		})
		builder.addCase(authThunks.autoLogin.fulfilled, (state, action) => {
			return action.payload?.user
		})
		//
		// Membership thunks
		//
		builder.addCase(
			thunks.getMembershipTransactionStatus.fulfilled,
			(state, action) => {
				const { userUpdates } = action.payload
				if (userUpdates.membershipPeriods) {
					state.membershipPeriods = userUpdates.membershipPeriods
				}
			}
		)
		//
		// Settings thunks
		//
		builder.addCase(thunks.changeEmail.fulfilled, (state, action) => {
			const { userUpdates } = action.payload
			if (userUpdates.email) {
				state.email = userUpdates.email
			}
		})
	}
})

export default slice.reducer
