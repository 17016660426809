import {
	BITCOIN_FARM_RANKS,
	BITCOIN_FARM_TIME_UNTIL_CAPPED_COLLECT_IN_MILLISECONDS,
	getCollectableBitcoinFarmCash
} from '@allardvanderouw/mercenariesonline-commons'
import {
	faClock,
	faDollarSign,
	faPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import {
	Avatar,
	Box,
	Button,
	Card,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { unwrapResult } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux'
import {
	collectBitcoinFarm,
	upgradeBitcoinFarm
} from '../../../redux/profile/thunks/bitcoinFarm'
import { ActiveDialog, DialogKey } from '../../../types'
import { getTimeFromMilliseconds } from '../../../util/date/get-time'
import ConfirmDialog from '../../Shared/ConfirmDialog'
import DialogHeader from './DialogHeader'

interface BitcoinFarmDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const BitcoinFarmDialog: React.FC<BitcoinFarmDialogProps> = ({
	setActiveDialog
}) => {
	const dispatch = useAppDispatch()
	const [isPurchasing, setIsPurchasing] = useState(false)
	const [isUpgrading, setIsUpgrading] = useState(false)
	const [isCollecting, setIsCollecting] = useState(false)
	const [
		isPurchaseConfirmationDialogOpen,
		setIsPurchaseConfirmationDialogOpen
	] = useState(false)
	const [isUpgradeConfirmationDialogOpen, setIsUpgradeConfirmationDialogOpen] =
		useState(false)
	const [errorText, setErrorText] = useState('')
	const [collectableCash, setCollectableCash] = useState(0)
	const [timeTillCapped, setTimeTillCapped] = useState('')

	const bitcoinFarm = useSelector(
		(state: RootState) => state.profile.entity.fixedAssets.bitcoinFarm
	)

	const cash = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.cash
	)

	const currentBitcoinFarmRank = BITCOIN_FARM_RANKS.find(
		(bitcoinFarmRank) => bitcoinFarmRank.level === bitcoinFarm.level
	)

	const nextBitcoinFarmRank = BITCOIN_FARM_RANKS.find(
		(bitcoinFarmRank) => bitcoinFarmRank.level === bitcoinFarm.level + 1
	)

	const handleClose = () => {
		setActiveDialog({ key: DialogKey.None })
	}

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	const handlePurchaseClick = () => {
		const notEnoughCash = Boolean(
			nextBitcoinFarmRank && cash < nextBitcoinFarmRank.cost
		)
		if (notEnoughCash) {
			setErrorText(t('app:Generic.notEnoughCash'))
			return
		}
		setIsPurchaseConfirmationDialogOpen(true)
	}

	const handlePurchaseConfirmClick = async () => {
		try {
			setIsPurchaseConfirmationDialogOpen(false)
			setIsPurchasing(true)
			const wrappedResult = await dispatch(upgradeBitcoinFarm())
			unwrapResult(wrappedResult) // unwrap to catch errors
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsPurchasing(false)
		}
	}

	const handlePurchaseCancelClick = async () => {
		setIsPurchaseConfirmationDialogOpen(false)
	}

	const handleUpgradeClick = () => {
		const notEnoughCash = Boolean(
			nextBitcoinFarmRank && cash < nextBitcoinFarmRank.cost
		)
		if (notEnoughCash) {
			setErrorText(t('app:Generic.notEnoughCash'))
			return
		}
		setIsUpgradeConfirmationDialogOpen(true)
	}

	const handleUpgradeConfirmClick = async () => {
		try {
			setIsUpgradeConfirmationDialogOpen(false)
			setIsUpgrading(true)
			const wrappedResult = await dispatch(upgradeBitcoinFarm())
			unwrapResult(wrappedResult) // unwrap to catch errors
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsUpgrading(false)
		}
	}

	const handleUpgradeCancelClick = async () => {
		setIsUpgradeConfirmationDialogOpen(false)
	}

	const handleCollectClick = async () => {
		if (collectableCash <= 0) {
			setErrorText(t('app:BitcoinFarm.noCollectableCash'))
			return
		}
		try {
			setIsCollecting(true)
			const wrappedResult = await dispatch(collectBitcoinFarm())
			unwrapResult(wrappedResult) // unwrap to catch errors
		} catch (error: any) {
			setErrorText(error?.message || t('app:FunctionError.UnexpectedError'))
		} finally {
			setIsCollecting(false)
		}
	}

	const refreshCollectableCash = useCallback(() => {
		const newCollectableCash = getCollectableBitcoinFarmCash(bitcoinFarm)
		setCollectableCash(newCollectableCash)
	}, [setCollectableCash, bitcoinFarm])

	const refreshTimeTillCapped = useCallback(() => {
		const timeAfterLastCollectInMilliseconds =
			new Date().valueOf() - new Date(bitcoinFarm.lastCollectedDate).valueOf()
		const timeTillCappedInMilliseconds =
			BITCOIN_FARM_TIME_UNTIL_CAPPED_COLLECT_IN_MILLISECONDS -
			timeAfterLastCollectInMilliseconds
		const newTimeTillCapped =
			timeTillCappedInMilliseconds > 0
				? getTimeFromMilliseconds(timeTillCappedInMilliseconds)
				: '0:00:00'
		setTimeTillCapped(newTimeTillCapped)
	}, [setTimeTillCapped, bitcoinFarm])

	useEffect(() => {
		const interval = setInterval(() => {
			refreshCollectableCash()
			refreshTimeTillCapped()
		}, 1000)
		refreshCollectableCash()
		refreshTimeTillCapped()
		return () => {
			clearInterval(interval)
		}
	}, [refreshCollectableCash, refreshTimeTillCapped])

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	let subheader
	if (bitcoinFarm.level === 0) {
		subheader = (
			<Typography variant="body2" color="secondary">
				{t('app:Generic.forSale')}
			</Typography>
		)
	} else {
		subheader = `${bitcoinFarm?.level} ${t('app:Generic.of')} ${
			BITCOIN_FARM_RANKS[BITCOIN_FARM_RANKS.length - 1].level
		}`
	}

	let purchaseCard
	if (bitcoinFarm.level === 0) {
		purchaseCard = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faDollarSign} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Generic.purchaseCost')}
						</Typography>
					}
					subheader={`${nextBitcoinFarmRank?.cost.toLocaleString()} ${t(
						'app:Profile.cash'
					).toLowerCase()}`}
				/>
				<LoadingButton
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					loading={isPurchasing}
					onClick={handlePurchaseClick}
				>
					{t('app:Generic.purchase')}
				</LoadingButton>
			</Card>
		)
	}

	let upgradeCard
	if (
		bitcoinFarm.level > 0 &&
		bitcoinFarm.level < BITCOIN_FARM_RANKS[BITCOIN_FARM_RANKS.length - 1].level
	) {
		upgradeCard = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faPlus} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Generic.level')}
						</Typography>
					}
					subheader={subheader}
				/>
				<Divider />
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faDollarSign} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Generic.upgradeCost')}
						</Typography>
					}
					subheader={`${nextBitcoinFarmRank?.cost.toLocaleString()} ${t(
						'app:Profile.cash'
					).toLowerCase()}`}
				/>
				<LoadingButton
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					loading={isUpgrading}
					onClick={handleUpgradeClick}
				>
					{t('app:Generic.upgrade')}
				</LoadingButton>
			</Card>
		)
	}

	let collectCard
	if (bitcoinFarm.level > 0) {
		collectCard = (
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faDollarSign} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:BitcoinFarm.cashFarmed')}
						</Typography>
					}
					subheader={`${collectableCash.toLocaleString()} ${t(
						'app:Generic.of'
					)} ${currentBitcoinFarmRank?.collect.toLocaleString()}`}
				/>
				<Divider />
				<CardHeader
					sx={{ p: 1 }}
					avatar={
						<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
							<SvgIcon fontSize="medium">
								<FontAwesomeIcon icon={faClock} color="#fff" />
							</SvgIcon>
						</Avatar>
					}
					title={
						<Typography variant="subtitle2">
							{t('app:Generic.timeTillFull')}
						</Typography>
					}
					subheader={`${timeTillCapped}`}
				/>
				<LoadingButton
					fullWidth
					variant="contained"
					sx={{ borderRadius: 0 }}
					loading={isCollecting}
					onClick={handleCollectClick}
				>
					{t('app:Generic.collect')}
				</LoadingButton>
			</Card>
		)
	}

	return (
		<>
			{errorDialog}
			<ConfirmDialog
				isOpen={isPurchaseConfirmationDialogOpen}
				cancel={handlePurchaseCancelClick}
				cancelButtonText={t('app:Generic.cancel')}
				confirm={handlePurchaseConfirmClick}
				confirmButtonText={t('app:Generic.purchase')}
				descriptionText={t('app:Generic.confirmPurchaseDescription', {
					thing: `${t('app:Generic.the')} ${t(
						'app:Profile.bitcoinFarm'
					).toLowerCase()}`,
					cost: `${nextBitcoinFarmRank?.cost.toLocaleString()} ${t(
						'app:Profile.cash'
					).toLowerCase()}`
				})}
				titleText={t('app:Generic.confirmPurchaseTitle')}
			/>
			<ConfirmDialog
				isOpen={isUpgradeConfirmationDialogOpen}
				cancel={handleUpgradeCancelClick}
				cancelButtonText={t('app:Generic.cancel')}
				confirm={handleUpgradeConfirmClick}
				confirmButtonText={t('app:Generic.upgrade')}
				descriptionText={t('app:Generic.confirmUpgradeDescription', {
					thing: `${t('app:Generic.the')} ${t(
						'app:Profile.bitcoinFarm'
					).toLowerCase()}`,
					cost: `${nextBitcoinFarmRank?.cost.toLocaleString()} ${t(
						'app:Profile.cash'
					).toLowerCase()}`
				})}
				titleText={t('app:Generic.confirmUpgradeTitle')}
			/>
			<Dialog open={true} fullWidth onClose={handleClose}>
				<DialogHeader
					activeDialogKey={DialogKey.BitcoinFarm}
					setActiveDialog={setActiveDialog}
				/>
				<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>
					<Stack width="100%" direction="row">
						<Box>
							<Stack
								width="100%"
								sx={{
									width: 275,
									zIndex: 1000,
									display: 'flex',
									backgroundColor: blueGrey[800],
									borderRight: `4px solid ${blueGrey[500]}`
								}}
							>
								<Stack flexGrow={1} padding={1} spacing={1}>
									{purchaseCard}
									{upgradeCard}
									{collectCard}
								</Stack>
							</Stack>
						</Box>
						<Box
							flexGrow={1}
							sx={{
								background: 'url("/assets/images/bitcoin-farm.jpg")',
								backgroundSize: 'cover',
								backgroundPosition: 'center'
							}}
						></Box>
					</Stack>
				</Box>
			</Dialog>
		</>
	)
}

export default BitcoinFarmDialog
