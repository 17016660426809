import {
	ProfilesWeaponPartsFactoryCollectResponseBody,
	ProfilesWeaponPartsFactoryUpgradeResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const upgradeWeaponPartsFactory = createAsyncThunk(
	'profiles/upgradeWeaponPartsFactory',
	async (): Promise<ProfilesWeaponPartsFactoryUpgradeResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/weaponPartsFactory/upgradeWeaponPartsFactory'
		)
		return response
	}
)

export const collectWeaponPartsFactory = createAsyncThunk(
	'profiles/collectWeaponPartsFactory',
	async (): Promise<ProfilesWeaponPartsFactoryCollectResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/weaponPartsFactory/collectWeaponPartsFactory'
		)
		return response
	}
)
