import {
	ProfilesMissionFinishRequestBody,
	ProfilesMissionFinishResponseBody,
	ProfilesMissionStartRequestBody,
	ProfilesMissionStartResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const startMission = createAsyncThunk(
	'profiles/startMission',
	async (
		payload: ProfilesMissionStartRequestBody
	): Promise<ProfilesMissionStartResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/missions/startMission',
			payload
		)
		return response
	}
)

export const finishMission = createAsyncThunk(
	'profiles/finishMission',
	async (
		payload: ProfilesMissionFinishRequestBody
	): Promise<ProfilesMissionFinishResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/missions/finishMission',
			payload
		)
		return response
	}
)
