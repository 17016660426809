import { FunctionError } from '@allardvanderouw/mercenariesonline-types'
import { t } from 'i18next'

export const getFunctionErrorDescription = (message: string) => {
	switch (message) {
		case FunctionError.BitcoinFarmMaxLevelReached:
			return t('app:FunctionError.BitcoinFarmMaxLevelReached')
		case FunctionError.BountyAlreadyAquired:
			return t('app:FunctionError.BountyAlreadyAquired')
		case FunctionError.BountyAlreadyCompletedForToday:
			return t('app:FunctionError.BountyAlreadyCompletedForToday')
		case FunctionError.BountyNotCompleted:
			return t('app:FunctionError.BountyNotCompleted')
		case FunctionError.BountyRewardNotFound:
			return t('app:FunctionError.BountyRewardNotFound')
		case FunctionError.EmailAlreadyExists:
			return t('app:FunctionError.EmailAlreadyExists')
		case FunctionError.HeroNameAlreadyExists:
			return t('app:FunctionError.HeroNameAlreadyExists')
		case FunctionError.HeroSlugAlreadyExists:
			return t('app:FunctionError.HeroSlugAlreadyExists')
		case FunctionError.InvalidCredentials:
			return t('app:FunctionError.InvalidCredentials')
		case FunctionError.InvalidInventoryKey:
			return t('app:FunctionError.InvalidInventoryKey')
		case FunctionError.InvalidMembershipStatus:
			return t('app:FunctionError.InvalidMembershipStatus')
		case FunctionError.InvalidPassword:
			return t('app:FunctionError.InvalidPassword')
		case FunctionError.InvalidPasswordResetToken:
			return t('app:FunctionError.InvalidPasswordResetToken')
		case FunctionError.InvalidRequestBody:
			return t('app:FunctionError.InvalidRequestBody')
		case FunctionError.InvalidUser:
			return t('app:FunctionError.InvalidUser')
		case FunctionError.InventoryFull:
			return t('app:FunctionError.InventoryFull')
		case FunctionError.ItemMaxLevelReached:
			return t('app:FunctionError.ItemMaxLevelReached')
		case FunctionError.ItemNotFound:
			return t('app:FunctionError.ItemNotFound')
		case FunctionError.ItemNotInEquipment:
			return t('app:FunctionError.ItemNotInEquipment')
		case FunctionError.ItemNotInInventory:
			return t('app:FunctionError.ItemNotInInventory')
		case FunctionError.ItemNotInLootBag:
			return t('app:FunctionError.ItemNotInLootBag')
		case FunctionError.ItemNotSalvageable:
			return t('app:FunctionError.ItemNotSalvageable')
		case FunctionError.ItemNotSellable:
			return t('app:FunctionError.ItemNotSellable')
		case FunctionError.ItemNotUpgradeable:
			return t('app:FunctionError.ItemNotUpgradeable')
		case FunctionError.NoSessionTokenFound:
			return t('app:FunctionError.NoSessionTokenFound')
		case FunctionError.NotEnoughCash:
			return t('app:FunctionError.NotEnoughCash')
		case FunctionError.NotEnoughWeaponParts:
			return t('app:FunctionError.NotEnoughWeaponParts')
		case FunctionError.PasswordResetTicketNotFound:
			return t('app:FunctionError.PasswordResetTicketNotFound')
		case FunctionError.ProfileMissionNotFound:
			return t('app:FunctionError.ProfileMissionNotFound')
		case FunctionError.ProfileMissionStoryTierAlreadyCompleted:
			return t('app:FunctionError.ProfileMissionStoryTierAlreadyCompleted')
		case FunctionError.ProfileMissionTooHighLevel:
			return t('app:FunctionError.ProfileMissionTooHighLevel')
		case FunctionError.ProfileNotFound:
			return t('app:FunctionError.ProfileNotFound')
		case FunctionError.ProfileUpdateFailed:
			return t('app:FunctionError.ProfileUpdateFailed')
		case FunctionError.SessionExpired:
			return t('app:FunctionError.SessionExpired')
		case FunctionError.SessionNotFound:
			return t('app:FunctionError.SessionNotFound')
		case FunctionError.TooManyLoginAttempts:
			return t('app:FunctionError.TooManyLoginAttempts')
		case FunctionError.TooManyPasswordResetRequests:
			return t('app:FunctionError.TooManyPasswordResetRequests')
		case FunctionError.TransactionNotFound:
			return t('app:FunctionError.TransactionNotFound')
		case FunctionError.UnexpectedError:
			return t('app:FunctionError.UnexpectedError')
		case FunctionError.UserNotFound:
			return t('app:FunctionError.UserNotFound')
		case FunctionError.UserUpdateFailed:
			return t('app:FunctionError.UserUpdateFailed')
		case FunctionError.WeaponPartsFactoryMaxLevelReached:
			return t('app:FunctionError.WeaponPartsFactoryMaxLevelReached')
		default:
			return t('app:FunctionError.UnexpectedError')
	}
}
