import {
	Alert,
	Box,
	Button,
	Card,
	CardHeader,
	Collapse,
	Divider,
	Stack,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import InventoryItemFragment from './InventoryItemFragment'

const Inventory: React.FC = () => {
	const [liftedInventoryKey, setLiftedInventoryKey] = useState<null | string>(
		null
	)

	const inventory = useSelector(
		(state: RootState) => state.profile.entity.inventory
	)

	const handleReleaseItemClick = () => {
		setLiftedInventoryKey(null)
	}

	// Map inventory to groups of 8 items
	const itemRows = Object.keys(inventory).reduce(
		(acc: JSX.Element[][], inventoryKey, index) => {
			const rowNumber = Math.floor(index / 8)
			if (!acc[rowNumber]) {
				acc[rowNumber] = []
			}
			const inventoryItem = inventory[inventoryKey as keyof typeof inventory]
			const inventoryItemFragmentkey = inventoryItem
				? `inventory-${inventoryItem.ID}`
				: `inventory-${inventoryKey}`
			acc[rowNumber].push(
				<InventoryItemFragment
					key={inventoryItemFragmentkey}
					inventoryKey={inventoryKey}
					inventoryItem={inventoryItem}
					liftedInventoryKey={liftedInventoryKey}
					setLiftedInventoryKey={setLiftedInventoryKey}
				/>
			)
			return acc
		},
		[]
	)

	// Map item groups to rows
	const rows = itemRows.map((itemRow, index) => (
		<Stack key={`row-${index}`} flexDirection="row">
			{itemRow}
		</Stack>
	))

	return (
		<>
			<Card sx={{ backgroundColor: blueGrey[700] }}>
				<CardHeader
					sx={{ p: 1 }}
					title={
						<Typography variant="subtitle2">
							{t('app:Armory.inventory')}
						</Typography>
					}
				/>
				<Divider />
				<Collapse in={Boolean(liftedInventoryKey)}>
					<Box px={1} pt={1} pb={0.5}>
						<Alert
							severity="info"
							action={
								<Button
									color="inherit"
									size="small"
									onClick={handleReleaseItemClick}
								>
									{t('app:Generic.cancel')}
								</Button>
							}
						>
							{t('app:Armory.liftedItem')}
						</Alert>
					</Box>
				</Collapse>
				<Box p={0.5}>{rows}</Box>
			</Card>
			<Alert variant="standard" severity="info" sx={{ py: 0.5 }}>
				{t('app:Armory.shiftClickNoConfirm')}
			</Alert>
		</>
	)
}

export default Inventory
