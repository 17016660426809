import { faPlus, faTint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Card,
	CardHeader,
	IconButton,
	LinearProgress,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { ActiveDialog, DialogKey } from '../../../types'

interface FuelCardProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const FuelCard: React.FC<FuelCardProps> = ({ setActiveDialog }) => {
	const fuel = useSelector(
		(state: RootState) => state.profile.entity.liquidAssets.fuel
	)

	const handlePlusClick = () => {
		setActiveDialog({ key: DialogKey.FuelPump })
	}

	return (
		<Card sx={{ backgroundColor: blueGrey[700] }}>
			<CardHeader
				sx={{ p: 1 }}
				avatar={
					<Avatar variant="rounded" sx={{ backgroundColor: blueGrey[500] }}>
						<SvgIcon fontSize="medium">
							<FontAwesomeIcon icon={faTint} color="#fff" />
						</SvgIcon>
					</Avatar>
				}
				title={
					<Typography variant="subtitle2">{t('app:Profile.fuel')}</Typography>
				}
				subheader={`${fuel.toLocaleString()} / 100`}
				action={
					<IconButton onClick={handlePlusClick}>
						<SvgIcon>
							<FontAwesomeIcon icon={faPlus} color="#fff" />
						</SvgIcon>
					</IconButton>
				}
			/>
			<LinearProgress color="secondary" value={fuel} variant="determinate" />
		</Card>
	)
}

export default FuelCard
