import { Item, ItemSlot } from '@allardvanderouw/mercenariesonline-types'
import { t } from 'i18next'

export const getItemDescription = (itemID: Item['ID']) => {
	switch (itemID) {
		case 'HANDGUN_T10_1':
			return t('app:Item.HANDGUN_T10_1')
		case 'HANDGUN_T15_1':
			return t('app:Item.HANDGUN_T15_1')
		case 'HANDGUN_T20_1':
			return t('app:Item.HANDGUN_T20_1')
		case 'HANDGUN_T25_1':
			return t('app:Item.HANDGUN_T25_1')
		case 'HANDGUN_T30_1':
			return t('app:Item.HANDGUN_T30_1')
		case 'HANDGUN_T35_1':
			return t('app:Item.HANDGUN_T35_1')
		case 'HANDGUN_T40_1':
			return t('app:Item.HANDGUN_T40_1')
		case 'HANDGUN_T45_1':
			return t('app:Item.HANDGUN_T45_1')
		case 'HANDGUN_T50_1':
			return t('app:Item.HANDGUN_T50_1')
		case 'ASSAULT_RIFLE_T10_1':
			return t('app:Item.ASSAULT_RIFLE_T10_1')
		case 'ASSAULT_RIFLE_T15_1':
			return t('app:Item.ASSAULT_RIFLE_T15_1')
		case 'ASSAULT_RIFLE_T20_1':
			return t('app:Item.ASSAULT_RIFLE_T20_1')
		case 'ASSAULT_RIFLE_T25_1':
			return t('app:Item.ASSAULT_RIFLE_T25_1')
		case 'ASSAULT_RIFLE_T30_1':
			return t('app:Item.ASSAULT_RIFLE_T30_1')
		case 'ASSAULT_RIFLE_T35_1':
			return t('app:Item.ASSAULT_RIFLE_T35_1')
		case 'ASSAULT_RIFLE_T40_1':
			return t('app:Item.ASSAULT_RIFLE_T40_1')
		case 'ASSAULT_RIFLE_T45_1':
			return t('app:Item.ASSAULT_RIFLE_T45_1')
		case 'ASSAULT_RIFLE_T50_1':
			return t('app:Item.ASSAULT_RIFLE_T50_1')
		case 'LIGHT_MACHINE_GUN_T10_1':
			return t('app:Item.LIGHT_MACHINE_GUN_T10_1')
		case 'LIGHT_MACHINE_GUN_T20_1':
			return t('app:Item.LIGHT_MACHINE_GUN_T20_1')
		case 'LIGHT_MACHINE_GUN_T30_1':
			return t('app:Item.LIGHT_MACHINE_GUN_T30_1')
		case 'LIGHT_MACHINE_GUN_T40_1':
			return t('app:Item.LIGHT_MACHINE_GUN_T40_1')
		case 'LIGHT_MACHINE_GUN_T50_1':
			return t('app:Item.LIGHT_MACHINE_GUN_T50_1')
		case 'HEAD_T10_1':
			return t('app:Item.HEAD_T10_1')
		case 'HEAD_T20_1':
			return t('app:Item.HEAD_T20_1')
		case 'HEAD_T30_1':
			return t('app:Item.HEAD_T30_1')
		case 'HEAD_T40_1':
			return t('app:Item.HEAD_T40_1')
		case 'HEAD_T50_1':
			return t('app:Item.HEAD_T50_1')
		case 'CHEST_T10_1':
			return t('app:Item.CHEST_T10_1')
		case 'CHEST_T20_1':
			return t('app:Item.CHEST_T20_1')
		case 'CHEST_T30_1':
			return t('app:Item.CHEST_T30_1')
		case 'CHEST_T40_1':
			return t('app:Item.CHEST_T40_1')
		case 'CHEST_T50_1':
			return t('app:Item.CHEST_T50_1')
		case 'LEGS_T10_1':
			return t('app:Item.LEGS_T10_1')
		case 'LEGS_T20_1':
			return t('app:Item.LEGS_T20_1')
		case 'LEGS_T30_1':
			return t('app:Item.LEGS_T30_1')
		case 'LEGS_T40_1':
			return t('app:Item.LEGS_T40_1')
		case 'LEGS_T50_1':
			return t('app:Item.LEGS_T50_1')
		case 'HANDS_T10_1':
			return t('app:Item.HANDS_T10_1')
		case 'HANDS_T15_1':
			return t('app:Item.HANDS_T15_1')
		case 'HANDS_T20_1':
			return t('app:Item.HANDS_T20_1')
		case 'HANDS_T25_1':
			return t('app:Item.HANDS_T25_1')
		case 'HANDS_T30_1':
			return t('app:Item.HANDS_T30_1')
		case 'HANDS_T35_1':
			return t('app:Item.HANDS_T35_1')
		case 'HANDS_T40_1':
			return t('app:Item.HANDS_T40_1')
		case 'HANDS_T45_1':
			return t('app:Item.HANDS_T45_1')
		case 'HANDS_T50_1':
			return t('app:Item.HANDS_T50_1')
		case 'FEET_T10_1':
			return t('app:Item.FEET_T10_1')
		case 'FEET_T15_1':
			return t('app:Item.FEET_T15_1')
		case 'FEET_T20_1':
			return t('app:Item.FEET_T20_1')
		case 'FEET_T25_1':
			return t('app:Item.FEET_T25_1')
		case 'FEET_T30_1':
			return t('app:Item.FEET_T30_1')
		case 'FEET_T35_1':
			return t('app:Item.FEET_T35_1')
		case 'FEET_T40_1':
			return t('app:Item.FEET_T40_1')
		case 'FEET_T45_1':
			return t('app:Item.FEET_T45_1')
		case 'FEET_T50_1':
			return t('app:Item.FEET_T50_1')
		case 'OTHER_T10_1':
			return t('app:Item.OTHER_T10_1')
		case 'OTHER_T15_1':
			return t('app:Item.OTHER_T15_1')
		case 'OTHER_T20_1':
			return t('app:Item.OTHER_T20_1')
		case 'OTHER_T25_1':
			return t('app:Item.OTHER_T25_1')
		case 'OTHER_T30_1':
			return t('app:Item.OTHER_T30_1')
		case 'OTHER_T35_1':
			return t('app:Item.OTHER_T35_1')
		case 'OTHER_T40_1':
			return t('app:Item.OTHER_T40_1')
		case 'OTHER_T45_1':
			return t('app:Item.OTHER_T45_1')
		case 'OTHER_T50_1':
			return t('app:Item.OTHER_T50_1')
		default:
			return ''
	}
}

export const getItemSlotDescription = (itemSlot: Item['slot']) => {
	switch (itemSlot) {
		case ItemSlot.Chest:
			return t('app:ItemSlot.CHEST')
		case ItemSlot.Feet:
			return t('app:ItemSlot.FEET')
		case ItemSlot.Hands:
			return t('app:ItemSlot.HANDS')
		case ItemSlot.Head:
			return t('app:ItemSlot.HEAD')
		case ItemSlot.Legs:
			return t('app:ItemSlot.LEGS')
		case ItemSlot.PrimaryWeapon:
			return t('app:ItemSlot.PRIMARY_WEAPON')
		case ItemSlot.SecondaryWeapon:
			return t('app:ItemSlot.SECONDARY_WEAPON')
		case ItemSlot.SpecialWeapon:
			return t('app:ItemSlot.SPECIAL_WEAPON')
		case ItemSlot.None:
			return t('app:ItemSlot.NONE')
		default:
			return ''
	}
}
