import { ProfilesLeaderboardGetStatsResponseBody } from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const getLeaderboardStats = createAsyncThunk(
	'profiles/getStats',
	async (): Promise<ProfilesLeaderboardGetStatsResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/leaderboard/getStats'
		)
		return response
	}
)
