import {
	UsersMembershipGetTransactionStatusRequestBody,
	UsersMembershipGetTransactionStatusResponseBody,
	UsersMembershipStartTransactionRequestBody,
	UsersMembershipStartTransactionResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const startMembershipTransaction = createAsyncThunk(
	'users/startMembershipTransaction',
	async (
		payload: UsersMembershipStartTransactionRequestBody
	): Promise<UsersMembershipStartTransactionResponseBody> => {
		const response = await callFunction(
			'/functions/users/membership/startTransaction',
			payload
		)
		return response
	}
)

export const getMembershipTransactionStatus = createAsyncThunk(
	'users/getMembershipTransactionStatus',
	async (
		payload: UsersMembershipGetTransactionStatusRequestBody
	): Promise<UsersMembershipGetTransactionStatusResponseBody> => {
		const response = await callFunction(
			'/functions/users/membership/getTransactionStatus',
			payload
		)
		return response
	}
)
