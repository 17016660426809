import { ProfileInventorySlot } from '@allardvanderouw/mercenariesonline-types'
import { Box } from '@mui/material'
import { ItemSource } from '../../../../types'
import ProfileItemFragment from './ProfileItemFragment'

interface InventoryItemFragmentProps {
	inventoryKey: string
	inventoryItem: ProfileInventorySlot
	divider?: boolean
	hasLiftedInventoryItem?: boolean
	liftedInventoryKey?: string | null
	setLiftedInventoryKey: (inventoryKey: string | null) => void
}

const InventoryItemFragment: React.FC<InventoryItemFragmentProps> = ({
	inventoryKey,
	inventoryItem,
	liftedInventoryKey,
	setLiftedInventoryKey
}) => {
	return (
		<Box p={0.5}>
			<ProfileItemFragment
				key={`${inventoryItem?.ID}-${inventoryItem?.level}`}
				item={inventoryItem}
				size="60px"
				source={ItemSource.Inventory}
				liftedInventoryKey={liftedInventoryKey}
				setLiftedInventoryKey={setLiftedInventoryKey}
				inventoryKey={inventoryKey}
			/>
		</Box>
	)
}

export default InventoryItemFragment
