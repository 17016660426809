import {
	AuthAutoLoginResponseBody,
	AuthForgotPasswordRequestBody,
	AuthForgotPasswordResponseBody,
	AuthLoginRequestBody,
	AuthLoginResponseBody,
	AuthRegisterRequestBody,
	AuthRegisterResponseBody,
	AuthResetPasswordRequestBody,
	AuthResetPasswordResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../util/functions/call-function'
import { setSessionToken } from '../../util/session'

export const login = createAsyncThunk(
	'auth/login',
	async (
		payload: AuthLoginRequestBody & { persist: boolean }
	): Promise<AuthLoginResponseBody> => {
		const { persist, ...loginPayload } = payload
		const response = await callFunction('/functions/auth/login', loginPayload)
		setSessionToken(response.sessionToken, persist)
		return response
	}
)

export const register = createAsyncThunk(
	'auth/register',
	async (
		payload: AuthRegisterRequestBody
	): Promise<AuthRegisterResponseBody> => {
		const response = await callFunction('/functions/auth/register', payload)
		return response
	}
)

export const autoLogin = createAsyncThunk(
	'auth/autoLogin',
	async (): Promise<AuthAutoLoginResponseBody | null> => {
		const response = await callFunction('/functions/auth/autoLogin')
		setSessionToken(response.sessionToken)
		return response
	}
)

export const logout = createAsyncThunk(
	'auth/logout',
	async (): Promise<null> => {
		await callFunction('/functions/auth/logout')
		setSessionToken(null)
		return null
	}
)

export const forgotPassword = createAsyncThunk(
	'auth/forgotPassword',
	async (
		payload: AuthForgotPasswordRequestBody
	): Promise<AuthForgotPasswordResponseBody> => {
		const response = await callFunction(
			'/functions/auth/forgotPassword',
			payload
		)
		return response
	}
)

export const resetPassword = createAsyncThunk(
	'auth/resetPassword',
	async (
		payload: AuthResetPasswordRequestBody
	): Promise<AuthResetPasswordResponseBody> => {
		const response = await callFunction(
			'/functions/auth/resetPassword',
			payload
		)
		return response
	}
)
