import { Box } from '@mui/material'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DialogKey } from '../../types'
import VersionUpdateNotification from '../Shared/VersionUpdateNotification'
import Dialogs from './Dialogs/Dialogs'
import Hud from './Hud/Hud'
import Map from './Map/Map'

const Game = () => {
	const [searchParams] = useSearchParams()
	const [activeDialog, setActiveDialog] = useState({
		key: (searchParams.get('dialog') as DialogKey) || DialogKey.None
	})
	const hud = useMemo(
		() => <Hud setActiveDialog={setActiveDialog} />,
		[setActiveDialog]
	)
	const map = useMemo(
		() => <Map setActiveDialog={setActiveDialog} />,
		[setActiveDialog]
	)

	return (
		<Box width="100%" height="100%">
			{hud}
			{map}
			<Dialogs activeDialog={activeDialog} setActiveDialog={setActiveDialog} />
			<VersionUpdateNotification activeDialogKey={activeDialog.key} />
		</Box>
	)
}

export default Game
