import {
	ProfilesMissionStartResponseBody,
	WindowMessage,
	WindowMessageType
} from '@allardvanderouw/mercenariesonline-types'
import { Dialog } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActiveDialog, DialogKey } from '../../../types'

interface PlayMissionDialogProps {
	isOpen: boolean
	startMissionPayload: ProfilesMissionStartResponseBody
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const PlayMissionDialog: React.FC<PlayMissionDialogProps> = ({
	isOpen,
	startMissionPayload,
	setActiveDialog
}) => {
	const [isClientReady, setIsClientReady] = useState(false)
	const iframeRef = useRef<HTMLIFrameElement | null>(null)

	const iframe = useMemo(() => {
		return (
			<iframe
				ref={iframeRef}
				title="client"
				width="100%"
				height="100%"
				style={{ border: 'none' }}
				src={process.env.REACT_APP_CLIENT_URL}
			></iframe>
		)
	}, [])

	const handleWindowMessage = useCallback(
		(message: MessageEvent<WindowMessage>) => {
			const messageType = message.data.type
			switch (messageType) {
				case WindowMessageType.ClientReady:
					setIsClientReady(true)
					break
				case WindowMessageType.FinishMission:
					if (message.data.payload) {
						setActiveDialog({
							key: DialogKey.FinishMission,
							data: message.data.payload.code
						})
					}
					break
				case WindowMessageType.ExitMission:
					setActiveDialog({
						key: DialogKey.None
					})
					break
				default:
					break
			}
		},
		[setActiveDialog]
	)

	useEffect(() => {
		window.addEventListener('message', handleWindowMessage)
		return () => window.removeEventListener('message', handleWindowMessage)
	}, [handleWindowMessage])

	useEffect(() => {
		if (
			isOpen &&
			isClientReady &&
			startMissionPayload &&
			iframeRef?.current?.contentWindow
		) {
			// Send start mission message to iframe
			const windowMessage: WindowMessage = {
				type: WindowMessageType.StartMission,
				payload: { code: startMissionPayload.meta.code }
			}
			iframeRef.current.contentWindow.postMessage(windowMessage, '*')
			iframeRef.current.focus()
		}
	}, [isClientReady, isOpen, startMissionPayload])

	return (
		<>
			<Dialog keepMounted open={isOpen} fullScreen>
				{iframe}
			</Dialog>
		</>
	)
}

export default PlayMissionDialog
