export const isValidEmail = (email: string) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export const isValidPassword = (password: string): boolean =>
	password.length >= 6 && password.length <= 100

export const isValidConfirmPassword = (
	confirmPassword: string,
	password: string
): boolean => confirmPassword === password

export const isValidProfileName = (profileName: string) => {
	const re = /^[a-zA-Z0-9]{6,20}$/
	return re.test(profileName)
}
