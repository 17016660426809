import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
	ActiveDialog,
	DialogKey,
	MembershipDialogStep
} from '../../../../types'
import DialogHeader from '../DialogHeader'
import MembershipStepNone from './MembershipStepNone'
import MembershipStepSuccess from './MembershipStepSuccess'

interface MembershipDialogProps {
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const MembershipDialog: React.FC<MembershipDialogProps> = ({
	setActiveDialog
}) => {
	const [searchParams] = useSearchParams()
	const [step, setStep] = useState(
		searchParams.get('step') ?? MembershipDialogStep.None
	)
	const [errorText, setErrorText] = useState('')

	const handleClose = useCallback(() => {
		setActiveDialog({ key: DialogKey.None })
	}, [setActiveDialog])

	const handleCloseErrorDialog = () => {
		setErrorText('')
	}

	const closeErrorDialog = () => {
		setErrorText('')
	}

	let errorDialog
	if (errorText) {
		errorDialog = (
			<Dialog open={true} onClose={closeErrorDialog}>
				<DialogContent>
					<Typography>{errorText}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleCloseErrorDialog}>
						{t('app:Generic.close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	let content
	if (step === MembershipDialogStep.Success) {
		content = (
			<MembershipStepSuccess
				checkoutID={searchParams.get('checkout_id')}
				setErrorText={setErrorText}
				setStep={setStep}
			/>
		)
	} else {
		content = <MembershipStepNone />
	}

	return (
		<>
			{errorDialog}
			<Dialog
				sx={{ width: '100%' }}
				open={true}
				maxWidth={false}
				onClose={handleClose}
			>
				<DialogHeader
					activeDialogKey={DialogKey.Membership}
					setActiveDialog={setActiveDialog}
					hideAssets={true}
				/>
				<Box sx={{ border: `4px solid ${blueGrey[500]}` }}>{content}</Box>
			</Dialog>
		</>
	)
}

export default MembershipDialog
