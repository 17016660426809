import { HeroRankTitle } from '@allardvanderouw/mercenariesonline-types'
import { t } from 'i18next'

export const getProfileRankTitleText = (title: HeroRankTitle) => {
	switch (title) {
		case HeroRankTitle.Recruit:
			return t('app:Profile.Ranks.Recruit')
		case HeroRankTitle.Private:
			return t('app:Profile.Ranks.Private')
		case HeroRankTitle.Corporal:
			return t('app:Profile.Ranks.Corporal')
		case HeroRankTitle.Sergeant:
			return t('app:Profile.Ranks.Sergeant')
		case HeroRankTitle.Lieutenant:
			return t('app:Profile.Ranks.Lieutenant')
		case HeroRankTitle.Captain:
			return t('app:Profile.Ranks.Captain')
		case HeroRankTitle.Major:
			return t('app:Profile.Ranks.Major')
		case HeroRankTitle.Colonel:
			return t('app:Profile.Ranks.Colonel')
		case HeroRankTitle.General:
			return t('app:Profile.Ranks.General')
		case HeroRankTitle.Admiral:
			return t('app:Profile.Ranks.Admiral')
		case HeroRankTitle.Marshal:
			return t('app:Profile.Ranks.Marshal')
		case HeroRankTitle.Commander:
			return t('app:Profile.Ranks.Commander')
		case HeroRankTitle.Chief:
			return t('app:Profile.Ranks.Chief')
		case HeroRankTitle.Overlord:
			return t('app:Profile.Ranks.Overlord')
	}
}
