let cachedSessionToken: string | null = null

export const setSessionToken = (
	sessionToken: string | null,
	persist?: boolean
) => {
	if (sessionToken) {
		const isAlreadyPersisted = Boolean(localStorage.getItem('sessionToken'))
		const saveToken = Boolean(
			persist === true || (persist === undefined && isAlreadyPersisted)
		)
		if (saveToken) {
			localStorage.setItem('sessionToken', sessionToken)
		}
	} else {
		localStorage.removeItem('sessionToken')
	}
	cachedSessionToken = sessionToken
}

export const getSessionToken = () => {
	if (!cachedSessionToken) {
		const sessionToken = localStorage.getItem('sessionToken')
		if (sessionToken) {
			const persist = true // token was already persisted in local storage
			setSessionToken(sessionToken, persist)
		}
	}
	return cachedSessionToken
}
