import { ProfilesFuelPumpCollectResponseBody } from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const collectFuelPump = createAsyncThunk(
	'profiles/collectFuelPump',
	async (): Promise<ProfilesFuelPumpCollectResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/fuelPump/collectFuelPump'
		)
		return response
	}
)
