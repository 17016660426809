export const getScoreLetter = (score: number | undefined): string => {
	if (score === undefined) {
		return 'F'
	}
	if (score >= 90) {
		return 'A+'
	}
	if (score >= 80) {
		return 'A'
	}
	if (score >= 70) {
		return 'B+'
	}
	if (score >= 60) {
		return 'B'
	}
	if (score >= 50) {
		return 'C+'
	}
	if (score >= 40) {
		return 'C'
	}
	if (score >= 30) {
		return 'D+'
	}
	if (score >= 20) {
		return 'D'
	}
	return 'F'
}
