import {
	getCollectableFuelPumpFuel,
	getFuelPumpCapacity
} from '@allardvanderouw/mercenariesonline-commons'
import { faGasPump, faTint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Avatar,
	Box,
	ButtonBase,
	Card,
	Stack,
	SvgIcon,
	Typography
} from '@mui/material'
import { blueGrey, grey, orange } from '@mui/material/colors'
import { LatLngExpression } from 'leaflet'
import { useCallback, useEffect, useState } from 'react'
import { Marker } from 'react-leaflet-marker'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { ActiveDialog, DialogKey } from '../../../../types'

interface FuelPumpMarkerProps {
	position: LatLngExpression
	setActiveDialog: (activeDialog: ActiveDialog) => void
}

const FuelPumpMarker: React.FC<FuelPumpMarkerProps> = ({
	position,
	setActiveDialog
}) => {
	const [collectableFuel, setCollectableFuel] = useState(0)

	const membershipPeriods = useSelector(
		(state: RootState) => state.user.membershipPeriods
	)
	const fuelPump = useSelector(
		(state: RootState) => state.profile.entity.fixedAssets.fuelPump
	)

	const fuelPumpCapacity = getFuelPumpCapacity(membershipPeriods)

	const refreshCollectableFuel = useCallback(() => {
		const newCollectableFuel = getCollectableFuelPumpFuel(
			fuelPump,
			membershipPeriods
		)
		setCollectableFuel(newCollectableFuel)
	}, [fuelPump, membershipPeriods])

	useEffect(() => {
		const interval = setInterval(() => {
			refreshCollectableFuel()
		}, 1000)
		refreshCollectableFuel()
		return () => {
			clearInterval(interval)
		}
	}, [refreshCollectableFuel])

	return (
		<div style={{ position: 'absolute' }}>
			<Marker position={position} size={[80, 100]}>
				<ButtonBase
					onClick={() => setActiveDialog({ key: DialogKey.FuelPump })}
				>
					<Card
						sx={{
							width: 80,
							height: 100,
							backgroundColor: blueGrey[700]
						}}
					>
						<Box padding={1}>
							<Avatar
								variant="rounded"
								sx={{
									backgroundColor: orange['A700'],
									width: 64,
									height: 64
								}}
							>
								<SvgIcon fontSize="large" sx={{ color: 'white' }}>
									<FontAwesomeIcon icon={faGasPump} />
								</SvgIcon>
							</Avatar>
						</Box>
						<Box
							height={20}
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{ backgroundColor: grey[300] }}
						>
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={0.5}
							>
								<SvgIcon fontSize="inherit">
									<FontAwesomeIcon color={blueGrey[800]} icon={faTint} />
								</SvgIcon>
								<Typography variant="caption" color={blueGrey[800]}>
									{collectableFuel.toLocaleString()} / {fuelPumpCapacity}
								</Typography>
							</Stack>
						</Box>
					</Card>
				</ButtonBase>
			</Marker>
		</div>
	)
}

export default FuelPumpMarker
