import {
	ProfilesArmoryCollectItemFromLootBagRequestBody,
	ProfilesArmoryCollectItemFromLootBagResponseBody,
	ProfilesArmoryEquipItemFromInventoryRequestBody,
	ProfilesArmoryEquipItemFromInventoryResponseBody,
	ProfilesArmoryMoveItemFromInventoryRequestBody,
	ProfilesArmoryMoveItemFromInventoryResponseBody,
	ProfilesArmorySalvageItemRequestBody,
	ProfilesArmorySalvageItemResponseBody,
	ProfilesArmorySellItemRequestBody,
	ProfilesArmorySellItemResponseBody,
	ProfilesArmoryUpgradeItemRequestBody,
	ProfilesArmoryUpgradeItemResponseBody
} from '@allardvanderouw/mercenariesonline-types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callFunction } from '../../../util/functions/call-function'

export const collectArmoryItemFromLootBag = createAsyncThunk(
	'profiles/collectArmoryItemFromLootBag',
	async (
		payload: ProfilesArmoryCollectItemFromLootBagRequestBody
	): Promise<ProfilesArmoryCollectItemFromLootBagResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/armory/collectItemFromLootBag',
			payload
		)
		return response
	}
)

export const equipArmoryItemFromInventory = createAsyncThunk(
	'profiles/equipArmoryItemFromInventory',
	async (
		payload: ProfilesArmoryEquipItemFromInventoryRequestBody
	): Promise<ProfilesArmoryEquipItemFromInventoryResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/armory/equipItemFromInventory',
			payload
		)
		return response
	}
)

export const moveArmoryItemFromInventory = createAsyncThunk(
	'profiles/moveArmoryItemFromInventory',
	async (
		payload: ProfilesArmoryMoveItemFromInventoryRequestBody
	): Promise<ProfilesArmoryMoveItemFromInventoryResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/armory/moveItemFromInventory',
			payload
		)
		return response
	}
)

export const salvageArmoryItem = createAsyncThunk(
	'profiles/salvageArmoryItem',
	async (
		payload: ProfilesArmorySalvageItemRequestBody
	): Promise<ProfilesArmorySalvageItemResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/armory/salvageItem',
			payload
		)
		return response
	}
)

export const sellArmoryItem = createAsyncThunk(
	'profiles/sellArmoryItem',
	async (
		payload: ProfilesArmorySellItemRequestBody
	): Promise<ProfilesArmorySellItemResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/armory/sellItem',
			payload
		)
		return response
	}
)

export const upgradeArmoryItem = createAsyncThunk(
	'profiles/upgradeArmoryItem',
	async (
		payload: ProfilesArmoryUpgradeItemRequestBody
	): Promise<ProfilesArmoryUpgradeItemResponseBody> => {
		const response = await callFunction(
			'/functions/profiles/armory/upgradeItem',
			payload
		)
		return response
	}
)
