import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { DialogKey } from '../../types'

interface VersionInformation {
	version: string
}

interface VersionUpdateNotificationProps {
	activeDialogKey: DialogKey
}

const VersionUpdateNotification: React.FC<VersionUpdateNotificationProps> = ({
	activeDialogKey
}) => {
	const [initialVersion, setInitialVersion] = useState<string | null>(null)
	const [hasNewVersion, setHasNewVersion] = useState(false)

	const isPlayingMission = useMemo(
		() => activeDialogKey === DialogKey.PlayMission,
		[activeDialogKey]
	)

	useEffect(() => {
		const checkHasNewVersion = async () => {
			if (isPlayingMission) {
				// Do not check for new version while playing a mission because it will reload the app
				return
			}

			try {
				// Fetch latest version from version.json in the public folder
				const response = await fetch('/version.json')
				const versionInformation: VersionInformation = await response.json()
				const { version } = versionInformation
				if (!version) {
					// Version missing, unable to complete the version check
					return
				}
				if (initialVersion === null) {
					// First check is used to set the initial version
					setInitialVersion(version)
				} else {
					// Next checks are used to see if a new version was deployed
					setHasNewVersion(initialVersion !== version)
					// setHasNewVersion(true)
				}
			} catch (error) {
				// Could not fetch or parse version information, unable to complete the version check
			}
		}

		// Immediately and periodically check if there is a new version
		checkHasNewVersion()
		const checkHasNewVersionInterval = setInterval(checkHasNewVersion, 600000) // 10 minutes

		// Clear the interval on effect cleanup
		return () => {
			clearInterval(checkHasNewVersionInterval)
		}
	}, [initialVersion, isPlayingMission])

	const handleReloadApp = () => {
		window.location.replace('/')
	}

	return (
		<Dialog open={hasNewVersion}>
			<DialogTitle>{t('app:Generic.VersionCheck.title')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('app:Generic.VersionCheck.description')}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					fullWidth
					onClick={handleReloadApp}
					autoFocus
				>
					{t('app:Generic.VersionCheck.update')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default VersionUpdateNotification
